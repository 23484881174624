require('tiny-date-picker');
//var domtoimage = require('dom-to-image');
import * as L from 'leaflet';
import TinyDatePicker from 'tiny-date-picker';
import * as PaintPolygon from './tracer';
import * as introJs from 'intro.js';

export function getClosest(el, selector) {
	if (typeof selector === 'string') {
		var matches = el.webkitMatchesSelector ? 'webkitMatchesSelector' : (el.msMatchesSelector ? 'msMatchesSelector' : 'matches');
		while (el.parentElement) {
			if (el[matches](selector)) {
				return el;
			};

			for (let child of el.children) {
				if (child[matches](selector)) {
					return child;
				};

				if (child.children) {
					for (let childrenChild of child.children) {
						if (childrenChild[matches](selector)) {
							return childrenChild;
						};
					}
				}
			}

			el = el.parentElement;
		}
	}

	return el;
}

export function Builder(key, test = 0, shop_on_behalf_of = 0) {
	var host = '';
	var apiKey = '';
	var builderElement;
	var type;
	var drawMap;
	var measurediv;
	var paintpolygonControl;
	var googleInputAutocomplete;
	var isWix;
	var uri = window.location.href;
	var clean_uri = window.location.href;
	var token = null;


	setCookie('_estimator_key_' + key, key, 3);
	apiKey = key;

	if (test != 0) {
		if (test === 1) {
			host = 'http://localhost:3001';
		} else {
			if (test.toString().match(/[3-9][0-9]{3}/)) {
				host = 'http://localhost:' + test;
			} else if (test === 's') {
				host = 'https://staging-api.simpleestimatesystems.com';
			} else {
				host = 'http://localhost:3001';

			}
		}
	} else {
		host = 'https://api.simpleestimatesystems.com';
	}
	console.log("In Builder, key = [" + key + "], test = [ " + test + "], host = [" + host + "], shop_on_behalf_of = [" + shop_on_behalf_of + "]");
	console.log("uri: " + uri);
	// Remove anything from end of uri after a # inclusive
	if (uri.indexOf("#") > 0) {
		clean_uri = uri.substring(0, uri.indexOf("#"));
	}
	console.log("clean_uri without #: " + clean_uri)
	// Remove anything from the beginning of uri before the ? inclusive
	if (clean_uri.indexOf("?") > 0) {
		clean_uri = clean_uri.substring(clean_uri.indexOf("?") + 1);
	}
	console.log("clean_uri without path and ?: " + clean_uri)
	const searchParams = new URLSearchParams(clean_uri);
	// for (const p of searchParams) {	console.log(p);	}
	if (searchParams.has("token")) {
		token = searchParams.get("token");
	}
	console.log("Token: " + token);
	// If token is null,     pass uri to backend as host parameter
	// If token is not null, pass token to backend as token parameter with act="abandoned_cart"
	const PCI_PROXY_MERCHANT_ID = '1100027679';

	builderElement = document.getElementById('builder');

	if (!builderElement) {
		console.warn('please put estimator element');
	} else {
		var css = '.load-wrapp{margin: 1rem 0; text-align:center;}.line {display: inline-block;width: 15px;height: 15px;border-radius: 15px;background-color: #000;}.load-3 .line:nth-last-child(1) {animation: loadingC .6s .1s linear infinite;}.load-3 .line:nth-last-child(2) {animation: loadingC .6s .2s linear infinite;}.load-3 .line:nth-last-child(3) {animation: loadingC .6s .3s linear infinite;} @keyframes loadingC {0 {transform: translate(0,0);}50% {transform: translate(0,15px);}100% {transform: translate(0,0);}}',
			head = document.head || document.getElementsByTagName('head')[0],
			style = document.createElement('style'),
			link = document.createElement('link');

		link.type = "text/css";
		link.rel = 'styleSheet'
		link.href = host + '/builder/theme.css?date=' + Date.now();
		head.appendChild(link);

		style.type = 'text/css';

		if (style.styleSheet) {
			style.styleSheet.cssText = css;
		} else {
			style.appendChild(document.createTextNode(css));
		}

		head.appendChild(style);
		builderElement.innerHTML = showLoading();
		link.onload = function () {
			//get options
			if (token != null) {
				getAbandonedCart()
			} else {
				getOptions();
			}
		}

		var fileref = document.createElement('script');
		fileref.setAttribute("type", "text/javascript");
		fileref.setAttribute("src", host + '/templator/v1/index.js?x-api-key=' + key);
		document.getElementsByTagName("body")[0].appendChild(fileref);
	}


	window.addEventListener('popstate', function (event) {
		var activeElement = document.querySelector('.builder-step.builder-active');
		activeElement.classList.remove('builder-active');
		activeElement.classList.add('builder-hidden');
		activeElement.previousElementSibling.classList.add('builder-active');
		activeElement.previousElementSibling.classList.remove('builder-hidden');
	}, false);


	document.addEventListener('click', (event) => {
		if (event.target.matches('#et-bt-cancel')) {
			if (measurediv) {
				measurediv.remove();
			}
			var activeElement = document.querySelector('.builder-step.builder-active');
			activeElement.classList.remove('builder-active');
			activeElement.classList.add('builder-hidden');
			builderElement.querySelector('#builder-form').classList.add('builder-active');
			builderElement.querySelector('#builder-form').classList.remove('builder-hidden');

		}

		if (event.target.matches('.estimator-input-to-cart')) {
			let variantForm = document.getElementById('builder-service-variant-form');
			if (variantForm) {
				let variantList = event.target.closest('#bt-et-variants');
				let service = event.target.getAttribute('data-service');
				let variant = event.target.getAttribute('name');

				if (variantList && service && variant) {
					let inputs = variantList.querySelectorAll('input[data-service="' + service + '"]');

					if (inputs && inputs.length) {
						inputs.forEach(function (input) {
							let inputVariant = input.getAttribute('name');
							if (inputVariant && input.type === "checkbox" && inputVariant !== variant) {
								input.checked = false;
							}
						});
					}
				}
			}
		}

		if (event.target.matches('.estimator-upsale-input-to-cart')) {
			let parent = event.target.parentNode.parentNode.parentNode.parentNode;
			let variantForm = document.getElementById('builder-service-variant-form');
			if (parent && variantForm) {
				let service = event.target.getAttribute('data-service');
				let variant = event.target.getAttribute('name');

				if (service && variant) {
					let inputs = parent.querySelectorAll('input[data-service="' + service + '"]');

					if (inputs && inputs.length) {
						inputs.forEach(function (input) {
							let inputVariant = input.getAttribute('name');
							if (inputVariant && input.type === "checkbox" && inputVariant !== variant) {
								input.checked = false;
							}
						});
					}
				}
			}
		}

		// If the clicked element doesn't have the right selector, bail
		if (event.target.matches('.estimator-btn-cart')) {
			// Don't follow the link
			event.preventDefault();
			getForm();
		}

		if (event.target.matches('#confirm-next')) {
			event.preventDefault();
			submitForm(false, true);
		}

		if (event.target.matches('#address-next')) {
			event.preventDefault();
			submitForm();
		}

		if (event.target.matches('#measure-next')) {
			event.preventDefault();
			submitForm(true);
		}

		if (event.target.matches('#measure-detail-next')) {
			event.preventDefault();
			submitForm(false, false, true);
		}

		if (event.target.matches('#service-next')) {
			console.log("In event.target.matches('#service-next')");
			event.preventDefault();
			submitForm(false, false, false, true);
		}

		if (event.target.matches('#estimator-main-next') || (event.target.parentNode && typeof (event.target.parentNode.matches) === 'function' && event.target.parentNode && event.target.parentNode.matches && event.target.parentNode.matches('#estimator-main-next'))) {
			prevNext(false);
		}

		if (event.target.matches('#estimator-main-back') || (event.target.parentNode && typeof (event.target.parentNode.matches) === 'function' && event.target.parentNode.matches('#estimator-main-back'))) {
			prevNext(true);
		}

		if (event.target.matches('#estimator-addy-reset')) {
			builderElement.querySelector('#estimator-addy-form').reset();
		}

		if (event.target.matches('#estimator-found-customer')) {
			//get data attributes, apply values

			let el = builderElement.querySelector('#estimator-addy-form').querySelector('.customer-exists').querySelector('span');

			let firstname = builderElement.querySelector("#estimator-fname"); // get name and other data from form
			let lastname = builderElement.querySelector("#estimator-lname");
			let phone = builderElement.querySelector("#estimator-phone");
			let email = builderElement.querySelector("#estimator-email");
			let street = builderElement.querySelector("#estimator-street");
			let city = builderElement.querySelector("#estimator-city");
			let state = builderElement.querySelector("#estimator-state");
			let postalcode = builderElement.querySelector("#estimator-postal");
			let longitude = builderElement.querySelector("#builder-tracer-longitude");
			let latitude = builderElement.querySelector("#builder-tracer-latitude");

			//check for google element
			var isGoogle = builderElement.querySelector('#go-bt-estimator-street') ? true : false;

			if (firstname && el.getAttribute('data-first')) {
				firstname.value = el.getAttribute('data-first');
			}

			if (lastname && el.getAttribute('data-last')) {
				lastname.value = el.getAttribute('data-last');
			}

			if (phone && el.getAttribute('data-phone')) {
				phone.value = el.getAttribute('data-phone');
			}

			if (email && el.getAttribute('data-email')) {
				email.value = el.getAttribute('data-email');
			}

			if (street && el.getAttribute('data-address')) {
				street.value = el.getAttribute('data-address');
			}

			if (city && el.getAttribute('data-city')) {
				city.value = el.getAttribute('data-city');
			}

			if (state && el.getAttribute('data-state')) {
				state.value = el.getAttribute('data-state');
			}

			if (postalcode && el.getAttribute('data-zip')) {
				postalcode.value = el.getAttribute('data-zip');
			}

			if (longitude && el.getAttribute('data-longitude')) {
				longitude.value = el.getAttribute('data-longitude');
			}

			if (latitude && el.getAttribute('data-latitude')) {
				latitude.value = el.getAttribute('data-latitude');
			}

			if (isGoogle) {
				builderElement.querySelector('#go-bt-estimator-street').value = el.getAttribute('data-address') + ' ' + el.getAttribute('data-city') + ' ' + el.getAttribute('data-state') + ' ' + el.getAttribute('data-zip');

				if (builderElement.querySelector("#builder-tracer-address")) {
					builderElement.querySelector("#builder-tracer-address").value = el.getAttribute('data-address');
				}

				if (builderElement.querySelector("#builder-tracer-city")) {
					builderElement.querySelector("#builder-tracer-city").value = el.getAttribute('data-city');
				}

				if (builderElement.querySelector("#builder-tracer-state")) {
					builderElement.querySelector("#builder-tracer-state").value = el.getAttribute('data-state');
				}

				if (builderElement.querySelector("#builder-tracer-zip")) {
					builderElement.querySelector("#builder-tracer-zip").value = el.getAttribute('data-zip');
				}
			}
		}

		if (event.target.matches('#estimator-delete-customer')) {
			eraseCookie('_branduffalo-builder-customer_' + apiKey);
			eraseCookie('_branduffalo-builder-cart_' + apiKey);
			eraseCookie('_branduffalo-builder-address_' + apiKey);
			builderElement.querySelector('#estimator-customer-exists').style['display'] = 'none';
			var span = builderElement.querySelector('#estimator-addy-form').querySelector('.customer-exists').querySelector('span');
			span.setAttribute('data-cart', '');
			span.setAttribute('data-first', '');
			span.setAttribute('data-last', '');
			span.setAttribute('data-email', '');
			span.setAttribute('data-phone', '');
			span.setAttribute('data-address', '');
			span.setAttribute('data-city', '');
			span.setAttribute('data-state', '');
			span.setAttribute('data-zip', '');
			span.setAttribute('data-longitude', '');
			span.setAttribute('data-latitude', '');
			builderElement.querySelector('#estimator-pre-customer-id').value = '';
			builderElement.querySelector('#estimator-pre-cart-id').value = '';
		}

		if (event.target.matches('#send-them-lead')) {
			event.preventDefault();
			sendThemLead(event.target);
		}

		if (event.target.matches('#builder-checkout-button')) {
			event.preventDefault();
			getCheckout(event.target);
		}

		if (event.target.matches('#builder-checkout-list-button')) {
			event.preventDefault();
			getCheckoutOrder(event.target);
		}

		if (event.target.matches('#builder-measurement-save-button')) {
			event.preventDefault();
			saveMeasurement(event.target);
		}


		if (event.target.matches('.bt-et-add-to-cart-back-view')) {
			//add backend
			addToCartView(event.target, event.target.getAttribute('data-variant'), event.target.getAttribute('data-fixed'), event.target.getAttribute('data-hour'), event.target.getAttribute('data-flat'), event.target.getAttribute('data-perhour'), event.target.getAttribute('data-overflow'), event.target.getAttribute('data-zip'));
		}

		if (event.target.matches('.bt-et-add-to-option-back-view')) {
			//add backend
			addToOptionView(event.target, event.target.getAttribute('data-variant'), event.target.getAttribute('data-fixed'), event.target.getAttribute('data-hour'), event.target.getAttribute('data-flat'), event.target.getAttribute('data-perhour'), event.target.getAttribute('data-overflow'), event.target.getAttribute('data-zip'));
		}

		if (event.target.matches('.x') || event.target.matches('.bt-et-remove-item-from-cart-back-view-icon')) {
			//remove item
			removeToCartView(event.target, event.target.getAttribute('data-variant'));
		}

		if (event.target.matches('.bt-et-remove-item-from-cart-back-view')) {
			//remove item
			// removeToCart(event.target, event.target.getAttribute('data-cartitem'));
			removeToCartView(event.target, event.target.getAttribute('data-variant'));

		}

		if (event.target.matches('#builder-order-button')) {
			event.preventDefault();
			submitOrder(event.target);
		}

		if (event.target.matches('#estimator-checkout-to-order-btn')) {
			orderCart(event.target);
		}

		if (event.target.matches('.estimator-btn-option-to-cart')) {
			addOption(event.target, event.target.getAttribute('data-variant'), event.target.getAttribute('data-fixed'), event.target.getAttribute('data-hour'), event.target.getAttribute('data-flat'), event.target.getAttribute('data-perhour'), event.target.getAttribute('data-overflow'), event.target.getAttribute('data-zip'))
		}

		if (event.target.matches('.estimator-btn-add-item-to-cart')) {
			addToCart(event.target, event.target.getAttribute('data-variant'), event.target.getAttribute('data-fixed'), event.target.getAttribute('data-hour'), event.target.getAttribute('data-flat'), event.target.getAttribute('data-perhour'), event.target.getAttribute('data-overflow'), event.target.getAttribute('data-zip'));
		}

		if (event.target.matches('.estimate-checkout-coupon-delete')) {
			event.preventDefault();
			deleteCoupon(event.target, event.target.getAttribute('data-coupon'));
		}

		if (event.target.matches('.estimator-btn-add-upsale-to-cart')) {
			addUpsale(event.target, event.target.getAttribute('data-upsale'), event.target.getAttribute('data-variant'), event.target.getAttribute('data-fixed'), event.target.getAttribute('data-hour'), event.target.getAttribute('data-perhour'), event.target.getAttribute('data-overflow'), event.target.getAttribute('data-zip'), event.target.classList.contains('on-checkout'));
		}

		if (event.target.matches('.estimator-btn-remove-item-from-cart')) {
			removeFromCart(event.target, event.target.getAttribute('data-cartitem'));
		}

		if (event.target.matches('.estimator-btn-remove-option-cart')) {
			removeFromCart(event.target, event.target.getAttribute('data-cartitem'));
		}

		if (event.target.matches('.estimator-btn-go-to-checkout')) {
			getCheckout(event.target, true);
		}

		if (event.target.matches('.estimator-basic-cart-hover')) {
			getCheckout(event.target, true);
		}

		if (event.target.matches('.estimator-btn-add-promocode')) {
			if (document.getElementById('estimator-promo-input')) {
				checkPromo(document.getElementById('estimator-promo-input').value);
			}
		}

		if (event.target.matches(".variant-acknowledgement")) {
			var closestButton = getClosest(event.target, '.estimator-btn');

			if (event.target.checked === true) {
				closestButton.disabled = false;
			} else {
				closestButton.disabled = true;
			}
		}
	});

	window.estimatorOnClose = function () {
		var el = document.querySelector('.estimator-global-modal');
		document.body.removeChild(el);
	}

	function setCookie(name, value, days) {
		var expires = "";
		if (days) {
			var date = new Date();
			date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
			expires = "; expires=" + date.toUTCString();
		}
		document.cookie = name + "=" + (value || "") + expires + "; path=/";
	}

	function getCookie(name) {
		var nameEQ = name + "=";
		var ca = document.cookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') c = c.substring(1, c.length);
			if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
		}
		return null;
	}

	function eraseCookie(name) {
		document.cookie = name + '=; Max-Age=-99999999;';
	}

	function showLoading() {
		return '<div class="load-wrapp"><div class="load-3"><div class="line"></div><div class="line"></div><div class="line"></div></div></div>';
	}

	function getOptions() {
		const url = host + '/templator/v1/take_action?act=start';
		fetch(url, {
			method: 'POST',
			body: {},
			headers: {
				'mode': 'no-cors',
				'Content-Type': 'text/html',
				'x-api-key': apiKey,
				'Accept': '*/*',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
				'Access-Control-Allow-Headers': 'X-PINGOTHER, Content-Type'
			}
		})
			.then((res) => {
				if (!res.ok) {
					return Promise.reject(res.json());
				}

				return Promise.resolve(res.text())
			})
			.then((data) => {
				document.getElementById('builder').innerHTML = data;
				getForm();
			})
			.catch(function (err) {
				console.log(err);
				err.then((error) => {
					showError(error.error);
				});
			});
	}

	function sendThemLead(el) {
		el.disabled = true;
		let cart = getCookie('_branduffalo-builder-cart_' + apiKey);
		const url = host + '/templator/v1/send_customer_lead_email/' + cart;
		console.log("sendThemLead URL: %s", url)
		fetch(url, {
			method: 'POST',
			body: {},
			headers: {
				'mode': 'no-cors',
				'Content-Type': 'text/html',
				'x-api-key': apiKey,
				'Accept': '*/*',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
				'Access-Control-Allow-Headers': 'X-PINGOTHER, Content-Type'
			}
		})
			.then((res) => {
				if (!res.ok) {
					return Promise.reject(res.json());
				}

				return Promise.resolve(res.text())
			})
			.then((data) => {
				console.log(data);
				const obj = JSON.parse(data);
				showSuccess(obj.message);
			})
			.catch(function (err) {
				console.log(err);
				err.then((error) => {
					showError(error.error);
				});
			});
	}

	function getAbandonedCart() {
		var url = host + '/templator/v1/take_action?act=start';
		fetch(url, {
			method: 'POST',
			body: {},
			headers: {
				'mode': 'no-cors',
				'Content-Type': 'text/html',
				'x-api-key': apiKey,
				'Accept': '*/*',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
				'Access-Control-Allow-Headers': 'X-PINGOTHER, Content-Type'
			}
		})
			.then((res) => {
				if (!res.ok) {
					return Promise.reject(res.json());
				}

				return Promise.resolve(res.text())
			})
			.then((data) => {
				document.getElementById('builder').innerHTML = data;
			})
			.catch(function (err) {
				console.log(err);
				err.then((error) => {
					showError(error.error);
				});
			});
		url = host + '/templator/v1/take_action?act=abandoned_cart&token=' + token + "&shop_on_behalf_of=" + shop_on_behalf_of;
		let data = {};
		if (shop_on_behalf_of > 0) {
			data["shop_on_behalf_of"] = shop_on_behalf_of;
		}
		fetch(url, {
			method: 'POST',
			body: JSON.stringify(data), // data can be `string` or {object}!
			headers: {
				'mode': 'no-cors',
				'Content-Type': 'text/html',
				'x-api-key': apiKey,
				'Accept': '*/*',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
				'Access-Control-Allow-Headers': 'X-PINGOTHER, Content-Type'
			}
		})
			.then((res) => {
				if (!res.ok) {
					return Promise.reject(res.json());
				}
				return Promise.resolve(res.text())
			})
			.then((data) => {
				history.pushState(
					{
						id: "services_variant"
					},
					"Instant Quoting",
					'#service_variant'
				)
				//	document.getElementById('et-bt-map-loading').remove();
				var html = data.trim(); // Never return a text node of whitespace as the result

				builderElement.querySelector('#builder-form').classList.add('builder-hidden');
				builderElement.querySelector('#builder-form').classList.remove('builder-active');
				builderElement.querySelector('#builder-services-variants').classList.remove('builder-hidden');
				builderElement.querySelector('#builder-services-variants').classList.add('builder-active');
				builderElement.querySelector('#builder-services-variants').innerHTML = html;

				if (builderElement.querySelector('#builder-services-variants') && builderElement.querySelector('#builder-data-hidden-main')) {
					if (builderElement.querySelector('#builder-services-variants').querySelector('#builder-data-hidden-services')) {
						let inputs = builderElement.querySelector('#builder-services-variants').querySelector('#builder-data-hidden-services').getElementsByTagName("input")

						if (inputs && inputs.length) {
							for (let input of inputs) {
								if (builderElement.querySelector('#' + input.getAttribute('data-hidden'))) {
									builderElement.querySelector('#' + input.getAttribute('data-hidden')).value = input.value;
									setCookie('_branduffalo-builder-' + input.getAttribute('data-tag') + '_' + apiKey, builderElement.querySelector('#' + input.getAttribute('data-hidden')).value, 3);
								}
							}
						}
					}
				}
				scrollToBuilder(document.getElementById('builder'), 0, 100);
				builderElement.scrollIntoView({ block: "center", behavior: "smooth" });
			})
			.catch(function (err) {
				document.getElementById('et-bt-map-loading').remove();
				err.then((error) => {
					showError(error.error);
				});
			});
	}

	function initializeGoogle() {
		var input = document.getElementById('go-bt-estimator-street');

		// input.addEventListener('blur', (event) => {
		// 	debugger;
		// });		
		var autocomplete = new google.maps.places.Autocomplete(input);
		var infowindow = new google.maps.InfoWindow();
		google.maps.event.addDomListener(window, 'load', initializeGoogle);

		google.maps.event.addListener(autocomplete, 'place_changed', (data) => {
			if (autocomplete.getPlace()) {
				if (autocomplete.getPlace().geometry && autocomplete.getPlace().geometry.location) {
					if (document.getElementById('builder-tracer-longitude')) {
						document.getElementById('builder-tracer-longitude').value = autocomplete.getPlace().geometry.location.lng();
					}

					if (document.getElementById('builder-tracer-latitude')) {
						document.getElementById('builder-tracer-latitude').value = autocomplete.getPlace().geometry.location.lat();
					}
				}

				var address = '';
				var city = '';
				var administrative_area_level_3 = '';
				for (let addyType of autocomplete.getPlace().address_components) {
					addyType.types.forEach((type) => {
						if (type === "street_number" || type === "route") {
							address += addyType.short_name + ' ';
						}

						if (type === "locality") {
							city = addyType.long_name;
							document.getElementById('builder-tracer-city').value = addyType.long_name;
						}

						if (type === "administrative_area_level_3") {
							administrative_area_level_3 = addyType.long_name;
						}

						if (type === "administrative_area_level_1") {
							document.getElementById('builder-tracer-state').value = addyType.long_name;
						}

						if (type === "postal_code") {
							document.getElementById('builder-tracer-zip').value = addyType.long_name;
						}
					});
				}

				if (address) {
					document.getElementById('builder-tracer-address').value = address;
				};
				if (!city && administrative_area_level_3) {
					document.getElementById('builder-tracer-city').value = administrative_area_level_3;
					console.log('WARNING: Google Maps Autocomplete found no locality: administrative_area_level_3 used')
				} else {
					if (!city && !administrative_area_level_3) {
						console.log('ERROR: Google Autocomplete found no locality or administrative_area_level_3 values for CITY');
					}
				}
			}
		});
	}

	function getForm() {
		let payload = {};
		let customer = getCookie('_branduffalo-builder-customer_' + apiKey);
		let cart = getCookie('_branduffalo-builder-cart_' + apiKey);
		let address = getCookie('_branduffalo-builder-address_' + apiKey);

		let url_variant = host + '/templator/v1/take_action?act=form';

		if (customer) {
			payload["customer"] = customer;
		}

		if (address) {
			payload["address"] = address;
		}

		if (cart) {
			payload["cart"] = cart;
		}

		fetch(url_variant, {
			method: 'POST', // or 'PUT'
			body: JSON.stringify(payload),
			headers: {
				'mode': 'no-cors',
				'Content-Type': 'text/html',
				'x-api-key': apiKey,
				'Accept': '*/*',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
				'Access-Control-Allow-Headers': 'X-PINGOTHER, Content-Type'
			}
		})
			.then((res) => {
				if (!res.ok) {
					return Promise.reject(res.json());
				}
				return Promise.resolve(res.text())
			})
			.then((data) => {
				history.pushState(
					{
						id: "forms"
					},
					"Instant Quoting",
					'#form')

				if (builderElement.querySelector('#builder-is-wix')) {
					isWix = builderElement.querySelector('#builder-is-wix').value;
				}

				if (builderElement.querySelector('#builder-form')) {
					builderElement.querySelector('#builder-form').classList.remove('builder-hidden');
					builderElement.querySelector('#builder-form').classList.add('builder-active');
					builderElement.querySelector('#builder-form').innerHTML = data;
					// if (document.getElementById('estimator-type')) {
					// 	type = document.getElementById('estimator-type').value;
					// 	if (type && type.toLowerCase() === "tracer") {
					if (document.getElementById("go-bt-estimator-street")) {


						var fileref = document.createElement('script');
						fileref.setAttribute("type", "text/javascript");
						fileref.id = "et-bt-googlemaps";
						fileref.setAttribute("src", "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=places&key=AIzaSyC8BTIWz_NT4Jed97nGE03kM1Ln8KN-NbE");
						document.getElementsByTagName("body")[0].appendChild(fileref);

						document.getElementById("et-bt-googlemaps").addEventListener('load', () => {
							initializeGoogle();
						});
					}
					// 	}
					// }
				}
			})
			.catch(function (err) {
				if (err.then) {
					err.then((error) => {
						showError(error.error);
					});
				} else {
					showError(err);
				}
			});
	}

	function resetForm() {
		builderElement.getElementById('addy-form').reset();
	}

	function submitForm(measure, confirm, measure_detail, ai_service) {
		let firstname, lastname, phone, email, street, city, state, postalcode, sizes, long, lat;
		if (builderElement.querySelector("#estimator-fname")) {
			firstname = builderElement.querySelector("#estimator-fname").value; // get name and other data from form
		}

		if (builderElement.querySelector("#estimator-lname")) {
			lastname = builderElement.querySelector("#estimator-lname").value;
		}

		if (builderElement.querySelector("#estimator-phone")) {
			phone = builderElement.querySelector("#estimator-phone").value;
		}

		if (builderElement.querySelector("#estimator-email")) {
			email = builderElement.querySelector("#estimator-email").value;
		}

		if (builderElement.querySelector("#estimator-street")) {
			street = builderElement.querySelector("#estimator-street").value;
		}

		if (builderElement.querySelector("#estimator-city")) {
			city = builderElement.querySelector("#estimator-city").value;
		}

		if (builderElement.querySelector("#estimator-state")) {
			state = builderElement.querySelector("#estimator-state").value;
		}

		if (builderElement.querySelector("#estimator-postal")) {
			postalcode = builderElement.querySelector("#estimator-postal").value;
		}

		//google tracer fields
		if (measure || confirm || measure_detail || ai_service) {
			if (!street && builderElement.querySelector("#builder-tracer-address")) {
				street = builderElement.querySelector("#builder-tracer-address").value;
			}

			if (!city && builderElement.querySelector("#builder-tracer-city")) {
				city = builderElement.querySelector("#builder-tracer-city").value;
			}

			if (!state && builderElement.querySelector("#builder-tracer-state")) {
				state = builderElement.querySelector("#builder-tracer-state").value;
			}

			if (!postalcode && builderElement.querySelector("#builder-tracer-zip")) {
				postalcode = builderElement.querySelector("#builder-tracer-zip").value;
			}

			if (builderElement.querySelector('#builder-sizes-id')) {
				sizes = builderElement.querySelector('#builder-sizes-id').value;
			}

			lat = builderElement.querySelector("#builder-tracer-latitude").value;
			long = builderElement.querySelector("#builder-tracer-longitude").value;
		}

		let valid = true;
		let questions;
		if (!email || !street || !postalcode || !firstname || !lastname || (builderElement.querySelector("#estimator-phone").required && !phone)) {
			valid = false;
		}

		let customer = '';
		if (document.getElementById("estimator-pre-customer-id")) {
			customer = document.getElementById("estimator-pre-customer-id").value;
		}
		let address = '';
		if (document.getElementById("estimator-pre-address-id")) {
			address = document.getElementById("estimator-pre-address-id").value;
		}
		let cart = '';
		if (document.getElementById("estimator-pre-cart-id")) {
			cart = document.getElementById("estimator-pre-cart-id").value;
		}

		if (document.getElementById('builder-questions')) {
			var inputs, textareas, selectTags, answers = [];

			inputs = document.getElementById('builder-questions').getElementsByTagName("input");
			textareas = document.getElementById('builder-questions').getElementsByTagName("textarea");
			selectTags = document.getElementById('builder-questions').getElementsByTagName("select");

			if (inputs && inputs.length) {
				for (let input of inputs) {
					if (input.required && !input.value) {
						valid = false;
					}

					var obj = {};

					if (input.type === "checkbox") {
						obj.question_id = input.name;
						if (input.checked) {
							document.querySelectorAll('input[name="' + input.name + '"]')?.forEach(function (v, index) {

								if (v.checked) {
									if (typeof (obj.answer) === 'object' && obj["answer"] && obj["answer"].length) {
										obj["answer"].push(index.toString());
									} else {
										obj["answer"] = [];
										obj["answer"].push(index.toString());
									}
								}
							});
						} else {
							obj["answer"] = '';
						}
					} else if (input.type === "radio") {
						if (input.checked) {
							obj.question_id = input.name;
							obj["answer"] = input.value;
							// document.querySelectorAll('input[name="' + input.name + '"]').forEach(function(v, index){
							// 	if (v.value === input.value) {
							// 			obj["answer"] = index.toString();
							// 	}
							// });
						}
					} else {
						obj["answer"] = input.value
					}

					if (Object.keys(obj).length > 0) {
						answers.push(obj);
					}
				};
			}

			if (textareas && textareas.length) {
				for (let txt of textareas) {
					if (txt.required && !txt.value) {
						valid = false;
					}

					var obj = {
						question_id: txt.id,
						answer: txt.value
					};

					answers.push(obj)
				};
			}

			if (selectTags && selectTags.length) {
				for (let select of selectTags) {
					if (select.required && !select.value) {
						valid = false;
					}
					var selectObj = {
						question_id: select.id,
						answer: select.value
					};
					answers.push(selectObj);
				};
			}
		}

		if (valid) {
			let data = {
				"first_name": firstname,
				"last_name": lastname,
				"email": email,
				"addresses_attributes": [{
					"address1": street,
					"address2": "",
					"zip_code": postalcode,
					"phone_number": phone,
					"alternative_phone": null,
					"default": true,
					"billing_default": false,
					"active": true,
					"address_type": "lead"
				}]
			};

			if (customer) {
				data["id"] = customer;
			}

			if (cart) {
				data["cart_id"] = cart;
			}

			if (address) {
				data["addresses_attributes"][0]["id"] = address;
			}

			if (state) {
				data["addresses_attributes"][0]["state_name"] = state;
			}

			if (city) {
				data["addresses_attributes"][0]["city"] = city;
			}

			if (long) {
				data["addresses_attributes"][0]["longitude"] = long;
			}

			if (lat) {
				data["addresses_attributes"][0]["latitude"] = lat;
			}

			if (answers) {
				data["answers"] = answers;
			}

			if (shop_on_behalf_of > 0) {
				data["shop_on_behalf_of"] = shop_on_behalf_of;
			}

			//  BEGIN Add loading component
			var template = document.createElement('div');
			var html = '<div id="et-bt-map-loading" class="loading" style="background: rgba(255,255,255,0.87); z-index: 9999999999999; position: fixed; top: 0; right: 0; bottom: 0; left: 0; width: 100vw; height: 100vh;">' + showLoading() + '</div>';
			template.innerHTML = html;
			document.getElementsByTagName("body")[0].appendChild(template);
			//  END   Add Loading component

			if (measure) {
				//builderElement.querySelector('#measure-next').disabled = true;

				const url = host + '/templator/v1/measure';
				fetch(url, {
					method: 'POST', // or 'PUT'
					body: JSON.stringify(data), // data can be `string` or {object}!
					headers: {
						'mode': 'no-cors',
						'Content-Type': 'application/json',
						'x-api-key': apiKey,
						'Accept': '*/*',
						'Access-Control-Allow-Origin': '*',
						'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
						'Access-Control-Allow-Headers': 'X-PINGOTHER, Content-Type'
					}
				}).then((res) => {
					if (!res.ok) {
						return Promise.reject(res.json());
					}
					return Promise.resolve(res.text())
				}).then((data) => {
					history.pushState(
						{
							id: "measure"
						},
						"Instant Quoting",
						'#measures')
					var html = data.trim(); // Never return a text node of whitespace as the result
					//
					measurediv = document.createElement('div');
					measurediv.id = 'bt-et-measure-div';
					measurediv.innerHTML = html;
					document.getElementsByTagName("body")[0].appendChild(measurediv);
					//builderElement.querySelector('#builder-measure').innerHTML = html;
					builderElement.querySelector('#builder-form').classList.add('builder-hidden');
					builderElement.querySelector('#builder-form').classList.remove('builder-active');
					builderElement.querySelector('#builder-measure_detail').classList.add('builder-hidden');
					builderElement.querySelector('#builder-measure_detail').classList.remove('builder-active');
					builderElement.querySelector('#builder-measure').classList.remove('builder-hidden');
					builderElement.querySelector('#builder-measure').classList.add('builder-active');


					if (measurediv) {
						if (measurediv.querySelector('#lawntracer-cook')) {
							let inputs = measurediv.querySelector('#lawntracer-cook').getElementsByTagName("input")

							if (inputs && inputs.length) {
								for (let input of inputs) {
									if (builderElement.querySelector('#' + input.getAttribute('data-hidden'))) {
										builderElement.querySelector('#' + input.getAttribute('data-hidden')).value = input.value;
										setCookie('_branduffalo-builder-' + input.getAttribute('data-tag') + '_' + apiKey, builderElement.querySelector('#' + input.getAttribute('data-hidden')).value, 3);
									}
								}
							}
						}
					}

					if (isWix) {
						// if (parent.document.querySelector('iframe')) {
						// 	//parent.document.querySelector('iframe').contentWindow.postMessage('true');
						// 	// parent.document.querySelector('iframe').style['position'] = 'fixed';
						// 	// parent.document.querySelector('iframe').style['top'] = 0;
						// 	// parent.document.querySelector('iframe').style['right'] = 0;
						// 	// parent.document.querySelector('iframe').style['bottom'] = 0;
						// 	// parent.document.querySelector('iframe').style['left'] = 0;
						// 	// parent.document.querySelector('iframe').style['width'] = '100vw';
						// 	// parent.document.querySelector('iframe').style['height'] = '100vh';
						// }
					}

					initTracer();

					document.getElementById('et-bt-map-loading').remove();
					//builderElement.querySelector('#measure-next').disabled = false;
				}).catch((err) => {
					document.getElementById('et-bt-map-loading').remove();
					//builderElement.querySelector('#measure-next').disabled = false;
					if (err.then) {
						err.then((error) => {
							showError(error.error);
						})
					} else {
						showError(err);
					}
				});
			}
			else if (measure_detail) {
				builderElement.querySelector('#measure-detail-next').disabled = true;

				const url = host + '/templator/v1/measurement_detail';
				fetch(url, {
					method: 'POST', // or 'PUT'
					body: JSON.stringify(data), // data can be `string` or {object}!
					headers: {
						'mode': 'no-cors',
						'Content-Type': 'application/json',
						'x-api-key': apiKey,
						'Accept': '*/*',
						'Access-Control-Allow-Origin': '*',
						'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
						'Access-Control-Allow-Headers': 'X-PINGOTHER, Content-Type'
					}
				}).then((res) => {
					if (!res.ok) {
						console.log("In showForm measure_detail: bad return code");
						return Promise.reject(res.json());
					}
					return Promise.resolve(res.text())
				}).then((data) => {
					history.pushState(
						{
							id: "measurement_detail"
						},
						"Instant Quoting",
						'#measurement_detail')
					var html = data.trim(); // Never return a text node of whitespace as the result

					builderElement.querySelector('#builder-form').classList.add('builder-hidden');
					builderElement.querySelector('#builder-form').classList.remove('builder-active');
					builderElement.querySelector('#builder-measure_detail').classList.remove('builder-hidden');
					builderElement.querySelector('#builder-measure_detail').classList.add('builder-active');
					builderElement.querySelector('#builder-measure_detail').innerHTML = html;
					document.getElementById('et-bt-map-loading').remove();


					if (document.querySelector('#lawntracer-cook')) {
						let inputs = document.querySelector('#lawntracer-cook').getElementsByTagName("input")
						if (inputs && inputs.length) {
							for (let input of inputs) {
								if (builderElement.querySelector('#' + input.getAttribute('data-hidden'))) {
									builderElement.querySelector('#' + input.getAttribute('data-hidden')).value = input.value;
									setCookie('_branduffalo-builder-' + input.getAttribute('data-tag') + '_' + apiKey, builderElement.querySelector('#' + input.getAttribute('data-hidden')).value, 3);
								}
							}
						}
					}

				}).catch((err) => {
					document.getElementById('et-bt-map-loading').remove();
					builderElement.querySelector('#measure-detail-next').disabled = false;
					err.then((error) => {
						console.log("In showForm measure-detail: Error: " + error.error + " - switching to TRACER");
						submitForm(true);
						//	showError(error.error);
					});
				});
			} else {
				console.log("In Default for submitForm");
				let url = host;
				if (ai_service) {
					console.log("ai_service found");
					url += '/templator/v1/get_and_save_measurements/';
					builderElement.querySelector('#service-next').disabled = true;
					data["host"] = uri;
				} else {
					url += '/templator/v1/take_action?act=services_variant';
					builderElement.querySelector('#address-next').disabled = true;
				}

				if (confirm) {
					url += '&confirm=true';
				}
				fetch(url, {
					method: 'POST', // or 'PUT'
					body: JSON.stringify(data),
					// data can be `string` or {object}!
					headers: {
						'mode': 'no-cors',
						'Content-Type': 'application/json',
						'x-api-key': apiKey,
						'Accept': '*/*',
						'Access-Control-Allow-Origin': '*',
						'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
						'Access-Control-Allow-Headers': 'X-PINGOTHER, Content-Type'
					}
				}).then((res) => {
					if (!res.ok) {
						if (ai_service) {
							console.log("In showForm ai_service: bad return code");
						}
						return Promise.reject(res.json());
					}
					return Promise.resolve(res.text())
				}).then((data) => {
					var html = data.trim(); // Never return a text node of whitespace as the result

					builderElement.querySelector('#builder-form').classList.add('builder-hidden');
					builderElement.querySelector('#builder-form').classList.remove('builder-active');
					if (confirm) {
						history.pushState(
							{
								id: "confirm"
							},
							"Instant Quoting",
							'#confirm')
						builderElement.querySelector('#builder-confirm').classList.remove('builder-hidden');
						builderElement.querySelector('#builder-confirm').classList.add('builder-active');
						builderElement.querySelector('#builder-confirm').innerHTML = html;
						document.getElementById('et-bt-map-loading').remove();


						if (builderElement.querySelector('#builder-confirm') && builderElement.querySelector('#builder-data-hidden-main')) {
							if (builderElement.querySelector('#builder-confirm').querySelector('#builder-data-hidden-services')) {
								let inputs = builderElement.querySelector('#builder-confirm').querySelector('#builder-data-hidden-services').getElementsByTagName("input")

								if (inputs && inputs.length) {
									for (let input of inputs) {
										if (builderElement.querySelector('#' + input.getAttribute('data-hidden'))) {
											builderElement.querySelector('#' + input.getAttribute('data-hidden')).value = input.value;

											if (input.getAttribute('data-tag')) {
												setCookie('_branduffalo-builder-' + input.getAttribute('data-tag') + '_' + apiKey, builderElement.querySelector('#' + input.getAttribute('data-hidden')).value, 3);
											}
										}
									}
								}
							}
						}
					} else {
						history.pushState(
							{
								id: "services_variant"
							},
							"Instant Quoting",
							'#service_variant');
						builderElement.querySelector('#builder-services-variants').classList.remove('builder-hidden');
						builderElement.querySelector('#builder-services-variants').classList.add('builder-active');
						document.getElementById('et-bt-map-loading').remove();

						builderElement.querySelector('#builder-services-variants').innerHTML = html;

						if (builderElement.querySelector('#builder-services-variants') && builderElement.querySelector('#builder-data-hidden-main')) {
							if (builderElement.querySelector('#builder-services-variants').querySelector('#builder-data-hidden-services')) {
								let inputs = builderElement.querySelector('#builder-services-variants').querySelector('#builder-data-hidden-services').getElementsByTagName("input")

								if (inputs && inputs.length) {
									for (let input of inputs) {
										if (builderElement.querySelector('#' + input.getAttribute('data-hidden'))) {
											builderElement.querySelector('#' + input.getAttribute('data-hidden')).value = input.value;

											if (input.getAttribute('data-tag')) {
												setCookie('_branduffalo-builder-' + input.getAttribute('data-tag') + '_' + apiKey, builderElement.querySelector('#' + input.getAttribute('data-hidden')).value, 3);
											}
										}
									}
								}
							}
						}
					}
					if (ai_service) {
						builderElement.querySelector('#service-next').disabled = false;
					} else {
						builderElement.querySelector('#address-next').disabled = false;
					}
					scrollToBuilder(document.getElementById('builder'), 0, 100);
					builderElement.scrollIntoView({ block: "center", behavior: "smooth" });
				}).catch((err) => {
					document.getElementById('et-bt-map-loading').remove();
					if (ai_service) {
						builderElement.querySelector('#service-next').disabled = false;
						err.then((error) => {
							console.log("In showForm ai_service: Error: " + error.error + " - switching to TRACER");
							submitForm(true);
						})

					} else {
						builderElement.querySelector('#address-next').disabled = false;
						err.then((error) => {
							showError(error.error);
						})
					};
				});
			}
		} else {
			showError('Please fill in required fields');
		}
	}


	function scrollToBuilder(element, to, duration) {
		if (duration <= 0) return;
		var difference = to - element.scrollTop;
		var perTick = difference / duration * 10;

		setTimeout(function () {
			// element.scrollTop = element.scrollTop + perTick;
			// if (element.scrollTop === to) return;
			// //scrollToBuilder(element, to, duration - 10);
			// element.scrollIntoView({ block: "center", behavior: "smooth" });
			window.scrollTo({
				'behavior': 'smooth',
				'left': 0,
				'top': element.offsetTop
			});
		}, 10);
	}

	function initTracer() {
		drawMap = L.map('lawntracer-mapid', {
		}).setView([
			builderElement.querySelector('#builder-tracer-latitude').value ? builderElement.querySelector('#builder-tracer-latitude').value : document.querySelector('#builder-measure-force-lat').value,
			builderElement.querySelector('#builder-tracer-longitude').value ? builderElement.querySelector('#builder-tracer-longitude').value : document.querySelector('#builder-measure-force-long').value
		], 20);

		L.tileLayer('http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
			subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
			minZoom: 0,
			maxZoom: 22,
			ext: 'png'
		}).addTo(drawMap);

		var $cont_control = builderElement.querySelector("#lawntracer-controlid");

		var color = "#0be576";

		if (builderElement.querySelector("#builder-tracer-active-color")) {
			color = builderElement.querySelector("#builder-tracer-active-color").value;
		}

		paintpolygonControl = L.control.paintPolygon({
			menu: false,
			isPerformance: true,
			position: 'bottomleft',
			radius: 6,
			layerOptions: {
				interactive: false, // if this is true, when touching over existing drawing, it will trigger map pan or pull-to-refresh of android
				color: color,
				weight: 0,
				fillOpacity: 1,
			},
			drawOptions: {
				color: '#263239',
				weight: 0,
				fillOpacity: 1,
				interactive: false
			},
			eraseOptions: {
				color: '#ff0000',
				weight: 0,
				fillOpacity: 1,
				interactive: false
			},
			layerEraseOptions: {
				interactive: false, // if this is true, when touching over existing drawing, it will trigger map pan or pull-to-refresh of android
				color: '#ff0000',
				weight: 0,
				fillOpacity: 1,
				color: "#ff324a",
			},
		}).addTo(drawMap);

		var drawBtn = document.getElementById("et-bt-draw"),
			eraseBtn = document.getElementById("et-bt-erase"),
			stopBtn = document.getElementById("et-bt-stop"),
			eraseAll = document.getElementById("et-bt-erase-all"),
			refreshBtn = document.getElementById("et-bt-refresh-tracer"),
			radiusInput = document.getElementById("et-bt-radius");

		//init
		drawBtn.disabled = true;
		eraseBtn.disabled = stopBtn.disabled = false;
		paintpolygonControl.startDraw();

		drawBtn.onclick = function () {
			drawBtn.disabled = true;
			eraseBtn.disabled = stopBtn.disabled = false;
			paintpolygonControl.startDraw();
		}

		eraseBtn.onclick = function () {
			eraseBtn.disabled = true;
			drawBtn.disabled = stopBtn.disabled = false;
			paintpolygonControl.startErase();
		}

		stopBtn.onclick = function () {
			stopBtn.disabled = true;
			eraseBtn.disabled = drawBtn.disabled = false;
			paintpolygonControl.stop();
		}

		// radiusInput.oninput = function(){
		// 		paintpolygonControl.setRadius(radiusInput.value)
		// }

		eraseAll.onclick = function () {
			paintpolygonControl.eraseAll();
			document.getElementById('bt-et-lawntracer-area').innerHTML = '<b>0</b> <span>SQUARE FEET</span>';
		}

		refreshBtn.onclick = function () {
			drawMap.remove();
			document.getElementById('lawntracer-mapid').className = '';
			document.getElementById('lawntracer-mapid').innerHTML = '';
			initTracer();
		}

		// var json_measure = JSON.parse(document.getElementById("lawntracer-data-measure").getAttribute("data-json"));
		// debugger;;
		// for (var key in json_measure) {
		// 		if (json_measure.hasOwnProperty(key)) {
		// 				var type = json_measure[key];
		// 				html += '<div class="measure-item"><div style="background-color: ' + type[0]['color'] + ';">' + key + '</div><div id="area' + i + '"><b>0</b> <span>SQUARE FEET</span></div><div class="flex-container">';
		// 				html += '<button id="drawBtn' + i + '" data-index="' + i + '" class="lt-mat-fab"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="18px" height="18px"><path d="M0 0h24v24H0z" fill="none"/><path d="M7 14c-1.66 0-3 1.34-3 3 0 1.31-1.16 2-2 2 .92 1.22 2.49 2 4 2 2.21 0 4-1.79 4-4 0-1.66-1.34-3-3-3zm13.71-9.37l-1.34-1.34c-.39-.39-1.02-.39-1.41 0L9 12.25 11.75 15l8.96-8.96c.39-.39.39-1.02 0-1.41z"/></svg></button>';
		// 				html += '<button id="eraseBtn' + i + '" data-index="' + i + '" class="lt-mat-fab secondary"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#f44455" width="18px" height="18px"  xml:space="preserve"><path d="m20.5 10.2c0.7-0.7 0.7-1.9 0-2.6l-6.2-6.2c-0.7-0.7-1.9-0.7-2.6 0l-9.9 9.9c-0.7 0.7-0.7 1.9 0 2.6l3.7 3.7c0.3 0.3 0.8 0.5 1.3 0.5h13.7c0.3 0 0.5-0.2 0.5-0.5v-1.5c0-0.3-0.2-0.5-0.5-0.5h-5.5l5.5-5.4zm-11.7-2.4l5.3 5.3-2.6 2.6h-4.4l-3.1-3.1 4.8-4.8z"/></svg></button>';
		// 				html += '<button id="stopBtn' + i + '" data-index="' + i + '" class="lt-mat-button flat close"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="18px" height="18px"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>&nbsp;CLOSE</button>';
		// 				html += '</div></div>';

		// 				// create instance
		// 				paintpolygonControl[i] = L.control.paintPolygon({
		// 						index: i,
		// 						menu: false,
		// 						position: 'bottomleft',
		// 						radius: 20,
		// 						layerOptions: {
		// 								color: type[0]['color'],
		// 								weight: type[0]['weight'],
		// 								fillOpacity: type[0]['fillOpacity']
		// 						},
		// 						drawOptions: {
		// 								color: type[0]['color'],
		// 								weight: type[0]['weight'],
		// 								fillOpacity: type[0]['fillOpacity']
		// 						},
		// 						eraseOptions: {
		// 								color: '#ff0000',
		// 								weight: 0,
		// 								fillOpacity: 1
		// 						}
		// 				}).addTo(map);

		// 				i++;
		// 		}
		// }
		// $cont_control.innerHTML = html;
		// create events for map controls
		// document.addEventListener('click', function (e) {
		// 		console.log('target: ' + e.target.nodeName);
		// 		if (e.target && ('BUTTON' === e.target.nodeName) && ('index' in e.target.dataset)) {
		// 				var id = e.target.id;
		// 				var idx = e.target.dataset.index;
		// 				switch (true) {
		// 						case id.startsWith('drawBtn'):
		// 								console.log('drawBtn' + idx + ': startDraw()');
		// 								// stop other instances
		// 								stopAllDrawing(paintpolygonControl, idx);
		// 								// button states
		// 								document.getElementById('drawBtn' + idx).disabled = true;
		// 								document.getElementById('eraseBtn' + idx).disabled = false;
		// 								document.getElementById('stopBtn' + idx).disabled = false;
		// 								// draw
		// 								paintpolygonControl[idx].startDraw();
		// 								break;
		// 						case id.startsWith('eraseBtn'):
		// 								console.log('eraseBtn' + idx + ': startErase()');
		// 								// stop other instances
		// 								stopAllDrawing(paintpolygonControl, idx);
		// 								// button states
		// 								document.getElementById('drawBtn' + idx).disabled = false;
		// 								document.getElementById('eraseBtn' + idx).disabled = true;
		// 								document.getElementById('stopBtn' + idx).disabled = false;
		// 								// erase
		// 								paintpolygonControl[idx].startErase();
		// 								break;
		// 						case id.startsWith('stopBtn'):
		// 								console.log('stopBtn' + idx + ': stop()');
		// 								// button states
		// 								document.getElementById('drawBtn' + idx).disabled = false;
		// 								document.getElementById('eraseBtn' + idx).disabled = false;
		// 								document.getElementById('stopBtn' + idx).disabled = true;
		// 								// stop
		// 								paintpolygonControl[idx].stop();
		// 								break;
		// 						default:
		// 								console.log('no match for event');
		// 				}
		// 		}
		// });

		document.getElementById('et-bt-save').onclick = function () {
			exportMap(drawMap);
			//console.log(paintpolygonControl.length);
		}

		if (builderElement.querySelector('#builder-estimate-option-show-instructions') && builderElement.querySelector('#builder-estimate-option-show-instructions').value && builderElement.querySelector('#builder-estimate-option-show-instructions').value === 'true') {
			var intro = introJs();
			intro.setOptions({
				steps: [
					{
						element: '#et-bt-draw',
						intro: 'Start drawing with our paint tool!'
					},
					{
						element: '#et-bt-stop',
						intro: 'Use the hand to move the map.'
					},
					{
						element: '#et-bt-erase',
						intro: 'Use the erase to erase some area. Use the trash to remove the entire painting'
					},
					{
						element: '.leaflet-control-zoom',
						intro: 'Zoom in and out with the zoom controls'
					}, {
						element: '#et-bt-save',
						intro: 'Save when your done to see your price!'
					}
				]
			});
			intro.start();

			intro.onexit(function () {
				showMapInstructions();
			});
		} else {
			showMapInstructions();
		}
	};

	async function exportMap(map) {
		var builderElement = document.getElementById('builder');
		let customer = getCookie('_branduffalo-builder-customer_' + apiKey);
		let cart = getCookie('_branduffalo-builder-cart_' + apiKey);
		let address;

		if (builderElement.querySelector('#builder-data-hidden-main') && builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-address-id')) {
			address = builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-address-id').value;
		}

		if (isWix) {
			//add this as a default
			if (builderElement.querySelector('#builder-data-hidden-main') && builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-customer-id')) {
				customer = builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-customer-id').value;
			}

			//add this as a default
			if (builderElement.querySelector('#builder-data-hidden-main') && builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-cart-id')) {
				cart = builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-cart-id').value;
			}
		}

		var dataUrl = await paintpolygonControl.capture();
		var area;

		if (paintpolygonControl.area) {
			area = paintpolygonControl.area.toString();
		}

		var template = document.createElement('div');
		var html = '<div id="et-bt-map-loading" class="loading" style="background: rgba(255,255,255,0.87); z-index: 9999999999999; position: fixed; top: 0; right: 0; bottom: 0; left: 0; width: 100vw; height: 100vh;">' + showLoading() + '</div>';
		template.innerHTML = html;
		document.getElementsByTagName("body")[0].appendChild(template);
		let data = {};
		if (shop_on_behalf_of > 0) {
			data["shop_on_behalf_of"] = shop_on_behalf_of;
		}
		data["image"] = dataUrl;
		data["size"] = area;
		data["host"] = uri;
		data["address_id"] = address;
		data["customer_id"] = customer;
		data["cart_id"] = cart;
		data["all_data"] = JSON.stringify(paintpolygonControl.getData());

		fetch(host + '/templator/v1/measurements/', {
			method: 'POST',
			body: JSON.stringify(data
				/*
				{
				all_data: JSON.stringify(paintpolygonControl.getData()),
				image: dataUrl,
				size: area,
				host: uri,
				address_id: address,
				customer_id: customer,//builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-customer-id').value,
				cart_id: cart_id//builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-cart-id').value
				  }
				*/
			),
			headers: {
				'mode': 'no-cors',
				'Content-Type': 'text/html',
				'x-api-key': apiKey,
				'Accept': '*/*',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
				'Access-Control-Allow-Headers': 'X-PINGOTHER, Content-Type'
			}
		})
			.then((res) => {
				if (!res.ok) {
					return Promise.reject(res.json());
				}
				return Promise.resolve(res.text())
			})
			.then((data) => {
				history.pushState(
					{
						id: "services_variant"
					},
					"Instant Quoting",
					'#service_variant'
				)
				measurediv.remove();
				document.getElementById('et-bt-map-loading').remove();
				var html = data.trim(); // Never return a text node of whitespace as the result

				builderElement.querySelector('#builder-form').classList.add('builder-hidden');
				builderElement.querySelector('#builder-form').classList.remove('builder-active');
				builderElement.querySelector('#builder-services-variants').classList.remove('builder-hidden');
				builderElement.querySelector('#builder-services-variants').classList.add('builder-active');
				builderElement.querySelector('#builder-services-variants').innerHTML = html;

				if (builderElement.querySelector('#builder-services-variants') && builderElement.querySelector('#builder-data-hidden-main')) {
					if (builderElement.querySelector('#builder-services-variants').querySelector('#builder-data-hidden-services')) {
						let inputs = builderElement.querySelector('#builder-services-variants').querySelector('#builder-data-hidden-services').getElementsByTagName("input")

						if (inputs && inputs.length) {
							for (let input of inputs) {
								if (builderElement.querySelector('#' + input.getAttribute('data-hidden'))) {
									builderElement.querySelector('#' + input.getAttribute('data-hidden')).value = input.value;
									setCookie('_branduffalo-builder-' + input.getAttribute('data-tag') + '_' + apiKey, builderElement.querySelector('#' + input.getAttribute('data-hidden')).value, 3);
								}
							}
						}
					}
				}
				scrollToBuilder(document.getElementById('builder'), 0, 100);
				builderElement.scrollIntoView({ block: "center", behavior: "smooth" });
			})
			.catch(function (err) {
				document.getElementById('et-bt-map-loading').remove();
				err.then((error) => {
					showError(error.error);
				});
			});
	}

	function stopAllDrawing(arr_controls, idx) {
		for (i = 0; i < arr_controls.length; i++) {
			if (i == idx) continue;
			if (null != arr_controls[i]._action) {
				arr_controls[i].stop();
				console.log('paintpolygonControl[' + i + '] stopped');
			}
		}
	};

	// getArea - called from PaintPolygon.js => _onMouseUp()
	function getArea(turf, idx) {
		if (paintpolygonControl[idx].getData()) {
			var polygon = turf.feature(paintpolygonControl[idx].getData().geometry);
			if (polygon) {
				var area = turf.area(polygon);
				if (area === 0 || area >= 0) {
					paintpolygonControl[idx].area = turf.convertArea(area, 'meters', 'feet');
					document.getElementById('bt-et-lawntracer-area' + idx).innerHTML = '<b>' + paintpolygonControl[idx].area.toFixed(0) + '</b> <span>SQUARE FEET</span>';
				}
			}
		}
	};

	function getCheckout(el, force) {
		el.disabled = true;

		let data = {}
		data.customer = builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-customer-id').value;
		data.cart = builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-cart-id').value;
		let size;
		let zip;

		if (builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-size-id')) {
			data.size = builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-size-id').value
		}

		if (builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-zip-id')) {
			data.zip = builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-zip-id').value
		}

		fetch(host + '/templator/v1/take_action?act=checkout', {
			method: 'POST', // or 'PUT'
			body: JSON.stringify(data),
			headers: {
				'mode': 'no-cors',
				'Content-Type': 'text/html',
				'x-api-key': apiKey,
				'Accept': '*/*',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
				'Access-Control-Allow-Headers': 'X-PINGOTHER, Content-Type'
			}
		})
			.then((res) => {
				el.disabled = false;
				if (!res.ok) {
					return Promise.reject(res.json());
				}
				return Promise.resolve(res.text())
			})
			.then((data) => {
				var html = data.trim(); // Never return a text node of whitespace as the result

				builderElement.querySelector('#builder-services-variants').classList.add('builder-hidden');
				builderElement.querySelector('#builder-services-variants').classList.remove('builder-active');
				builderElement.querySelector('#builder-checkout').classList.remove('builder-hidden');
				builderElement.querySelector('#builder-checkout').classList.add('builder-active');
				builderElement.querySelector('#builder-checkout').innerHTML = html;
				scrollToBuilder(document.getElementById('builder'), 0, 100);
				builderElement.scrollIntoView({ block: "center", behavior: "smooth" });
			})
			.catch((err) => {
				err.then((error) => {
					showError(error.error);
				});
			});
	}

	function serializeOrder(form) {
		if (!form || form.nodeName !== "FORM") {
			return;
		}
		var i, j, checkboxes = [];
		var q = {};
		for (i = form.elements.length - 1; i >= 0; i = i - 1) {
			if (form.elements[i].name === "") {
				continue;
			}
			switch (form.elements[i].nodeName) {
				case 'INPUT':
					switch (form.elements[i].type) {
						case 'text':
						case 'hidden':
						case 'password':
						case 'button':
						case 'reset':
						case 'submit':
							q[form.elements[i].name] = form.elements[i].value;
							break;
						case 'checkbox':
							const valies = [];
							if (form.elements[i].classList.contains('estimator-step-to-cart')) {
								break;
							}
							if (form.elements[i].checked) {
								const el = getClosest(form.elements[i], '.bt-et-order-steps');
								if (el) {
									var orderinputs = el.querySelectorAll('input');
									orderinputs.forEach((oi) => {
										if (oi.checked) {
											valies.push(oi.name);
										}
									});
								}


								const notes = getClosest(form.elements[i], '.bt-et-order-notes');
								checkboxes.push({
									variant_id: form.elements[i].name,
									rate_fixed_id: form.elements[i].getAttribute('data-fixed'),
									rate_per_hour_id: form.elements[i].getAttribute('data-perhour'),
									rate_hour_id: form.elements[i].getAttribute('data-hour'),
									zip_id: form.elements[i].getAttribute('data-zip'),
									rate_flat_id: form.elements[i].getAttribute('data-flat'),
									rate_overflow_id: form.elements[i].getAttribute('data-overflow'),
									steps: valies.toString(),
									notes: notes.value
								});
							}
							break;
						case 'radio':
							if (form.elements[i].checked) {
								q[form.elements[i].name] = form.elements[i].value;
							}
							break;
					}
					break;
				case 'file':
					break;
				case 'TEXTAREA':
					q.push(form.elements[i].name + "=" + encodeURIComponent(form.elements[i].value));
					break;
				case 'SELECT':
					switch (form.elements[i].type) {
						case 'select-one':
							q.push(form.elements[i].name + "=" + encodeURIComponent(form.elements[i].value));
							break;
						case 'select-multiple':
							for (j = form.elements[i].options.length - 1; j >= 0; j = j - 1) {
								if (form.elements[i].options[j].selected) {
									q.push(form.elements[i].name + "=" + encodeURIComponent(form.elements[i].options[j].value));
								}
							}
							break;
					}
					break;
				case 'BUTTON':
					switch (form.elements[i].type) {
						case 'reset':
						case 'submit':
						case 'button':
							q.push(form.elements[i].name + "=" + encodeURIComponent(form.elements[i].value));
							break;
					}
					break;
			}
		}

		q["cart_items"] = checkboxes;
		return q;
	}

	function getCheckoutOrder(el) {
		el.disabled = true;
		if (builderElement.getElementsByClassName("cart-count")[1].querySelectorAll('span')[0].innerHTML == '0 Items') {
			return showError("Please make a selection before checking out");
		}
		let form = builderElement.querySelector('#builder-service-variant-form');
		let data = serializeOrder(form);
		const url = host + '/templator/v1/take_action?act=cart_checkout';
		fetch(url, {
			method: 'POST', // or 'PUT'
			body: JSON.stringify({
				order_form: data
			}), // data can be `string` or {object}!
			headers: {
				'mode': 'no-cors',
				'Content-Type': 'application/json',
				'x-api-key': apiKey,
				'Accept': '*/*',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
				'Access-Control-Allow-Headers': 'X-PINGOTHER, Content-Type'
			}
		}).then((res) => {
			if (!res.ok) {
				return Promise.reject(res.json());
			}
			return Promise.resolve(res.text())
		}).then((data) => {
			history.pushState(
				{
					id: "checkout"
				},
				"Instant Quoting",
				'#checkout');
			var html = data.trim(); // Never return a text node of whitespace as the result
			var showCreditCard = false;

			builderElement.querySelector('#builder-services-variants').classList.add('builder-hidden');
			builderElement.querySelector('#builder-services-variants').classList.remove('builder-active');
			builderElement.querySelector('#builder-checkout').classList.remove('builder-hidden');
			builderElement.querySelector('#builder-checkout').classList.add('builder-active');
			builderElement.querySelector('#builder-checkout').innerHTML = html;

			if (builderElement.querySelector('#builder-estimate-option-enable-credit-card') && builderElement.querySelector('#builder-estimate-option-enable-credit-card').value === 'true') {
				showCreditCard = true;
			}

			if (showCreditCard) {
				renderCreditCard();
			}

			// setCookie('_builder-customer_' + apiKey, builderElement.querySelector('#builder-checkout').querySelector('#main').querySelector('#variants').querySelector('#builder-customer-id').value, 3);
			// setCookie('_builder-address_' + apiKey, builderElement.querySelector('#builder-checkout').querySelector('#main').querySelector('#variants').querySelector('#builder-address-id').value, 3);
			// setCookie('_builder-cart_' + apiKey, builderElement.querySelector('#builder-checkout').querySelector('#main').querySelector('#variants').querySelector('#builder-cart-id').value, 3);

			scrollToBuilder(document.getElementById('builder'), 0, 100);
			builderElement.scrollIntoView({ block: "center", behavior: "smooth" });
		}).catch((err) => {
			err.then((error) => {
				showError(error.error);
			});
		});
	}

	function saveMeasurement(el) {
		el.disabled = true;
		var builderElement = document.getElementById('builder');
		let customer = getCookie('_branduffalo-builder-customer_' + apiKey);
		let cart = getCookie('_branduffalo-builder-cart_' + apiKey);
		let address;
		let sizes;

		if (builderElement.querySelector('#builder-data-hidden-main') && builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-address-id')) {
			address = builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-address-id').value;
		}

		if (builderElement.querySelector('#builder-data-hidden-services') && builderElement.querySelector('#builder-data-hidden-services').querySelector('#builder-sizes-id')) {
			sizes = builderElement.querySelector('#builder-data-hidden-services').querySelector('#builder-sizes-id').value;
		}

		if (isWix) {
			//add this as a default
			if (builderElement.querySelector('#builder-data-hidden-main') && builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-customer-id')) {
				customer = builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-customer-id').value;
			}

			//add this as a default
			if (builderElement.querySelector('#builder-data-hidden-main') && builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-cart-id')) {
				cart = builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-cart-id').value;
			}
		}

		var dataUrl = document.getElementById('measurement-image').src;
		var area = parseInt(document.getElementById('total-lawn-area').innerText);


		var template = document.createElement('div');
		var html = '<div id="et-bt-map-loading" class="loading" style="background: rgba(255,255,255,0.87); z-index: 9999999999999; position: fixed; top: 0; right: 0; bottom: 0; left: 0; width: 100vw; height: 100vh;">' + showLoading() + '</div>';
		template.innerHTML = html;
		document.getElementsByTagName("body")[0].appendChild(template);
		let data = {};
		data["image"] = dataUrl;
		data["size"] = area;
		data["sizes"] = sizes;
		data["host"] = uri;
		data["address_id"] = address;
		data["customer_id"] = customer;
		data["cart_id"] = cart;
		data["all_data"] = null;

		fetch(host + '/templator/v1/measurements/', {
			method: 'POST',
			body: JSON.stringify(data
				/* 
				{
				all_data: null,
				image: dataUrl,
				size: area,
				sizes: sizes,
				//testing: 'TESTING123 at saveMeasurement() line 1873',
				host: uri,
				address_id: address,
				customer_id: customer,//builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-customer-id').value,
				cart_id: cart//builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-cart-id').value
				}
				*/
			),
			headers: {
				'mode': 'no-cors',
				'Content-Type': 'text/html',
				'x-api-key': apiKey,
				'Accept': '*/*',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
				'Access-Control-Allow-Headers': 'X-PINGOTHER, Content-Type'
			}
		})
			.then((res) => {
				if (!res.ok) {
					return Promise.reject(res.json());
				}
				return Promise.resolve(res.text())
			})
			.then((data) => {
				history.pushState(
					{
						id: "services_variant"
					},
					"Instant Quoting",
					'#service_variant'
				)

				document.getElementById('et-bt-map-loading').remove();
				var html = data.trim(); // Never return a text node of whitespace as the result

				builderElement.querySelector('#builder-measure_detail').classList.add('builder-hidden');
				builderElement.querySelector('#builder-measure_detail').classList.remove('builder-active');
				builderElement.querySelector('#builder-services-variants').classList.remove('builder-hidden');
				builderElement.querySelector('#builder-services-variants').classList.add('builder-active');
				builderElement.querySelector('#builder-services-variants').innerHTML = html;

				if (builderElement.querySelector('#builder-services-variants') && builderElement.querySelector('#builder-data-hidden-main')) {
					if (builderElement.querySelector('#builder-services-variants').querySelector('#builder-data-hidden-services')) {
						let inputs = builderElement.querySelector('#builder-services-variants').querySelector('#builder-data-hidden-services').getElementsByTagName("input")

						if (inputs && inputs.length) {
							for (let input of inputs) {
								if (builderElement.querySelector('#' + input.getAttribute('data-hidden'))) {
									builderElement.querySelector('#' + input.getAttribute('data-hidden')).value = input.value;
									setCookie('_branduffalo-builder-' + input.getAttribute('data-tag') + '_' + apiKey, builderElement.querySelector('#' + input.getAttribute('data-hidden')).value, 3);
								}
							}
						}
					}
				}
				scrollToBuilder(document.getElementById('builder'), 0, 100);
				builderElement.scrollIntoView({ block: "center", behavior: "smooth" });
			})
			.catch(function (err) {
				document.getElementById('et-bt-map-loading').remove();
				err.then((error) => {
					showError(error.error);
				});
			});
	}


	function renderCreditCard() {
		console.log('Credit card rendered');
		loadPCIProxyScript();
		setExpiryInputFilter(document.getElementById('expiry'));
	}

	function loadPCIProxyScript() {
		const scriptSource = test
			? 'https://pay.sandbox.datatrans.com/upp/payment/js/secure-fields-2.0.0.js'
			: 'https://pay.datatrans.com/upp/payment/js/datatrans-2.0.0.js';

		if (document.querySelector('script[src="' + scriptSource + '"]')) {
			console.log('secure-fields script already loaded');
			initSecureFields();
			return;
		}

		const script = document.createElement('script');
		script.src = scriptSource;
		script.onload = function () {
			console.log('secure-fields script on load');
			initSecureFields();
		};
		document.body.appendChild(script);
	}

	function initSecureFields() {
		console.log('initializing secure fields');
		window.secureFields = new window.SecureFields();

		window.secureFields.initTokenize(
			PCI_PROXY_MERCHANT_ID,
			{
				cardNumber: {
					placeholderElementId: 'card-number',
					inputType: 'tel'
				},
				cvv: {
					placeholderElementId: 'cvv-number',
					inputType: 'tel'
				}
			}
		);

		bindSecureFieldsEvents(window.secureFields);
	}

	function bindSecureFieldsEvents(secureFields) {
		const orderBtn = document.getElementById('estimator-checkout-to-order-btn');

		secureFields.on('ready', function () {
			console.log('on ready secure fields');

			secureFields.setStyle('cardNumber', 'font: 16px/18px system-ui, sans-serif; border-radius: 0; border:0; -webkit-appearance: none; padding: 0');
			secureFields.setStyle('cvv', 'font: 16px/18px system-ui, sans-serif; border-radius: 0; border:0; -webkit-appearance: none; padding: 0');

			// secureFields.focus('cardNumber');
			orderBtn.disabled = true;

			secureFields.setPlaceholder('cardNumber', '4242 4242 4242 4242');
			secureFields.setPlaceholder('cvv', '123');
		});

		const cardContainer = document.getElementById('card-number-container');
		const cvvContainer = document.getElementById('cvv-container');
		const paymentContainer = document.getElementById('payment-container');
		const cardholderNameInput = document.getElementById('cardholder-name');
		const expiryInput = document.getElementById('expiry');

		// Set focus to input fields when clicking containers
		cardContainer.addEventListener('click', function () {
			secureFields.focus('cardNumber');
		});
		cvvContainer.addEventListener('click', function () {
			secureFields.focus('cvv');
		});

		// Submit
		document.getElementById('form-submit').addEventListener('click', function () {
			let errors = [];

			if (!validateCardholderName(cardholderNameInput.value)) {
				cardholderNameInput.classList.add('field__has-error');
				errors.push("Cardholder\'s name is not valid");
			} else {
				cardholderNameInput.classList.remove('field__has-error');
			}

			if (!validateExpiry(expiryInput.value)) {
				paymentContainer.classList.add('field__has-error');
				errors.push('Expiry date is not valid');
			} else {
				paymentContainer.classList.remove('field__has-error');
			}

			if (errors.length) {
				showError(errors.join('<br>'));
			} else {
				secureFields.submit();
			}
		});

		// Set class names when fields change
		secureFields.on('change', function (data) {
			var cardImage = cardContainer.querySelector('.secure-field--card-icon__recognized-card');
			paymentContainer.classList.remove('secure-field__has-focus-cvv');
			paymentContainer.classList.remove('secure-field__has-focus-cardNumber');
			paymentContainer.classList.remove('field__has-error');
			cardContainer.classList.remove('secure-field__has-error');
			cvvContainer.classList.remove('secure-field__has-error');

			paymentContainer.classList.add(`secure-field__has-focus-${data.event.field}`);

			if (!data.fields.cardNumber.paymentMethod) {
				cardImage.src = host + '/builder/credit-card/images/card-empty.svg';
				cardContainer.classList.remove('secure-field__is-recognized');
			} else {
				cardImage.src = host + '/builder/credit-card/images/brands/' + data.fields.cardNumber.paymentMethod + '.svg';
				cardContainer.classList.add('secure-field__is-recognized');
			}
		});

		// Set class names on validate
		secureFields.on('validate', function (data) {
			let errors = [];

			paymentContainer.classList.remove('field__has-error');

			if (data.fields.cardNumber.valid) {
				cardContainer.classList.remove('secure-field__has-error');
			} else {
				errors.push("Card number is not valid");

				cardContainer.classList.remove('secure-field__is-recognized');
				cardContainer.classList.add('secure-field__has-error');
				paymentContainer.classList.add('field__has-error');
			}

			if (data.fields.cvv.valid) {
				cvvContainer.classList.remove('secure-field__has-error');
			} else {
				errors.push("CVV number is not valid");

				cvvContainer.classList.add('secure-field__has-error');
				paymentContainer.classList.add('field__has-error');
			}

			if (errors.length) showError(errors.join('<br>'));
		});

		secureFields.on('success', function (data) {
			if (data.transactionId) {
				const result = document.getElementById('form-result');
				const expiryDateArr = expiryInput.value.split('/');

				result.innerText = 'Credit card was saved successfully';
				result.style.display = 'block';

				data.customerId = builderElement
					.querySelector('#builder-data-hidden-main')
					.querySelector('#builder-customer-id')
					.value;
				data.cardholderName = cardholderNameInput.value
				data.expMonth = expiryDateArr[0];
				data.expYear = expiryDateArr[1];

				submitTransactionResponse(data);
				orderBtn.disabled = false;
			}
		});
	}

	function setExpiryInputFilter(input) {
		const EXPIRY_REGEX = /^\d{0,2}[\/]?\d{0,2}/;

		['input', 'keydown', 'keyup', 'mousedown', 'mouseup', 'select', 'contextmenu', 'drop'].forEach(function (key) {
			input.addEventListener(key, function (e) {
				if (/^\d+$/.test(e.key)) {
					this.value = formatExpiry(this.value);
				}

				if (EXPIRY_REGEX.test(this.value)) {
					this.oldValue = this.value;
					this.oldSelectionStart = this.selectionStart;
					this.oldSelectionEnd = this.selectionEnd;
				} else if (this.hasOwnProperty('oldValue')) {
					this.value = this.oldValue;
					this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
				} else {
					this.value = '';
				}
			});
		});
	}

	function formatExpiry(val) {
		const p1 = parseInt(val[0], 10),
			p2 = parseInt(val[1], 10);

		return /^\d$/.test(val) && '0' !== val && '1' !== val
			? "0" + val + '/'
			: /^\d\d$/.test(val)
				? p2 > 2 && 0 !== p1 ? '0' + p1 + '/' + p2 : '' + val + '/'
				: val
	}

	function validateExpiry(exp) {
		const b = exp.split(/\D/);
		const d = new Date();
		const century = d.getFullYear() / 100 | 0;
		// Generate date for first day of following month
		const expires = new Date(century + b[1], b[0], 1);
		return d < expires;
	}

	function validateCardholderName(value) {
		const MIN_LENGTH = 2;
		const MAX_LENGTH = 26;
		const CARDHOLDER_NAME_REGEX = /^[\d\s-]*$/;

		if (typeof value !== 'string' || value.length < MIN_LENGTH || value.length > 26) {
			return false;
		}

		if (CARDHOLDER_NAME_REGEX.test(value)) {
			return false;
		}

		return true;
	}

	function submitTransactionResponse(data) {
		console.log(data);
		const url = host + '/templator/v1/store_transaction_details';
		fetch(url, {
			method: 'POST',
			body: JSON.stringify(data),
			headers: {
				'mode': 'no-cors',
				'Content-Type': 'application/json',
				'x-api-key': apiKey,
				'Accept': '*/*',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
				'Access-Control-Allow-Headers': 'X-PINGOTHER, Content-Type'
			}
		})
			.then((res) => {
				if (!res.ok) {
					return Promise.reject(res.json());
				}

				return Promise.resolve(res.text())
			})
			.then((data) => {
				var response = JSON.parse(data);
				if (response.credit_card_id && builderElement.querySelector("#builder-credit-card-id")) {
					builderElement.querySelector("#builder-credit-card-id").value = response.credit_card_id;
				}
			})
			.catch(function (err) {
				console.log(err);
				err.then((error) => {
					showError(error.error);
				});
			});
	}

	function submitOrder(el) {
		if (document.getElementById('builder-acknowledgements')) {
			if (document.getElementById('builder-acknowledgements').querySelectorAll('input').length) {
				try {
					var foundUnchecked = document.getElementById('builder-acknowledgements').querySelectorAll('input').forEach(function (val) {
						if (val.type === "checkbox" && val.required && !val.checked) {
							throw "Please accept agreements";
						}

						if (val.type === 'text' && val.required && !val.value) {
							throw "Please accept agreements";
						}
					});
				} catch (e) {
					showError(e);
					return;
				}
			}
		}

		let form = builderElement.querySelector('#builder-service-variant-form');
		let data = serializeOrder(form);

		const url = host + '/templator/v1/take_action?act=services_order';
		fetch(url, {
			method: 'POST', // or 'PUT'
			body: JSON.stringify({
				order_form: data
			}), // data can be `string` or {object}!
			headers: {
				'mode': 'no-cors',
				'Content-Type': 'application/json',
				'x-api-key': apiKey,
				'Accept': '*/*',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
				'Access-Control-Allow-Headers': 'X-PINGOTHER, Content-Type'
			}
		}).then((res) => {
			if (!res.ok) {
				return Promise.reject(res.json());
			}
			return Promise.resolve(res.text())
		}).then((data) => {
			// add parameters to urls 
			if (builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-checkout-redirect-url') && builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-checkout-redirect-url').value) {
				var url = builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-checkout-redirect-url').value;

				if (url.indexOf('email=') >= 0 && builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-customer-email') && builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-customer-email').value) {
					url = url.replace('EMAIL', builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-customer-email').value)
				}
				if (url.indexOf('phone=') >= 0 && builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-customer-phone') && builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-customer-phone').value) {
					url = url.replace('PHONE', builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-customer-phone').value)
				}
				if (url.indexOf('first_name=') >= 0 && builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-customer-firstname') && builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-customer-firstname').value) {
					url = url.replace('FIRSTNAME', builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-customer-firstname').value)
				}
				if (url.indexOf('last_name=') >= 0 && builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-customer-lastname') && builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-customer-lastname').value) {
					url = url.replace('LASTNAME', builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-customer-lastname').value)
				}
				window.location.href = url;
				builderElement.innerHTML = showLoading();
				//el
			} else {
				history.pushState(
					{
						id: "order"
					},
					"Instant Quoting",
					'#order');
				var html = data.trim(); // Never return a text node of whitespace as the result

				builderElement.querySelector('#builder-services-variants').classList.add('builder-hidden');
				builderElement.querySelector('#builder-services-variants').classList.remove('builder-active');
				builderElement.querySelector('#builder-order').classList.remove('builder-hidden');
				builderElement.querySelector('#builder-order').classList.add('builder-active');
				builderElement.querySelector('#builder-order').innerHTML = html;
			}
			// setCookie('_builder-customer_' + apiKey, builderElement.querySelector('#builder-checkout').querySelector('#main').querySelector('#variants').querySelector('#builder-customer-id').value, 3);
			// setCookie('_builder-address_' + apiKey, builderElement.querySelector('#builder-checkout').querySelector('#main').querySelector('#variants').querySelector('#builder-address-id').value, 3);
			// setCookie('_builder-cart_' + apiKey, builderElement.querySelector('#builder-checkout').querySelector('#main').querySelector('#variants').querySelector('#builder-cart-id').value, 3);
			scrollToBuilder(document.getElementById('builder'), 0, 100);
			builderElement.scrollIntoView({ block: "center", behavior: "smooth" });
		}).catch((err) => {
			err.then((error) => {
				showError(error.error);
			});
		});
	}

	function addOption(el, variant, rate_fixed, rate_hour, rate_flat, per_hour, overflow, zip) {
		let data_variant = {
			"variant_id": variant,
			"rate_fixed_id": rate_fixed,
			"rate_flat_id": rate_flat,
			"hour_id": rate_hour,
			"rate_per_hour_id": per_hour,
			"rate_overflow_id": overflow,
			"zip": zip
		};
		let size;

		if (builderElement.querySelector('#estimator-checkout').querySelector('#main').querySelector('#variants').querySelector('#estimator-size-id')) {
			size = builderElement.querySelector('#estimator-checkout').querySelector('#main').querySelector('#variants').querySelector('#estimator-size-id').value
		}

		if (size) {
			data_variant["size"] = size;
		}


		if (el && el.parentNode.querySelector('.option-quantity-input') && el.parentNode.querySelector('.option-quantity-input').value) {
			data_variant.quantity = el.parentNode.querySelector('.option-quantity-input').value;
		}

		let customerId = builderElement.querySelector('#estimator-checkout').querySelector('#main').querySelector('#variants').querySelector('#estimator-customer-id').value;
		let cartId = builderElement.querySelector('#estimator-checkout').querySelector('#main').querySelector('#variants').querySelector('#estimator-cart-id').value;

		const url_variant = host + '/pub/v1/customers/' + customerId + '/carts/' + cartId + '/cart_options';
		fetch(url_variant, {
			method: 'POST', // or 'PUT'
			body: JSON.stringify(data_variant), // data can be `string` or {object}!
			headers: {
				'mode': 'no-cors',
				'Content-Type': 'application/json',
				'x-api-key': apiKey,
				'Accept': '*/*',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
				'Access-Control-Allow-Headers': 'X-PINGOTHER, Content-Type'
			}
		})
			.then((res) => {
				if (!res.ok) {
					return Promise.reject(res.json());
				}
				return Promise.resolve(res.json())
			})
			.then((data) => {
				el.parentNode.classList.add('added');

				for (let mm = 0; mm < data.cart_items.length; mm++) {
					if (data.cart_items[mm].variant_id === variant) {
						el.nextElementSibling.setAttribute('data-cartitem', data.cart_items[mm].id);
					}
				}

				setTotal(data.total);
			})
			.catch((err) => {
				err.then((error) => {
					showError(error.error);
				});
			});
	}

	function checkPromo(value) {
	  let customerId = builderElement.querySelector('#builder-data-hidden-main #builder-customer-id').value //.querySelector('#main').querySelector('#variants').querySelector('#estimator-customer-id').value;
	  let cartId = builderElement.querySelector('#builder-data-hidden-main #builder-cart-id').value //.querySelector('#main').querySelector('#variants').querySelector('#estimator-cart-id').value;

		const url_variant = host + '/pub/v1/customers/' + customerId + '/carts/' + cartId + '/promos?promo=' + value;

		fetch(url_variant, {
			method: 'GET',
			headers: {
				'mode': 'no-cors',
				'Content-Type': 'application/json',
				'x-api-key': apiKey,
				'Accept': '*/*',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'GET, OPTIONS, DELETE',
				'Access-Control-Allow-Headers': 'X-PINGOTHER, Content-Type'
			}
		})
			.then((res) => {
				if (!res.ok) {
					return Promise.reject(res.json());
				}
				return Promise.resolve(res.text())
			})
			.then((data) => {
				if (document.getElementById('table-wrapper')) {
					document.getElementById('table-wrapper').innerHTML = data;
				}
			})
			.catch(function (err) {
				err.then((error) => {
					showError(error.error);
				});
			});
	}

	function removeFromCart(el, cartItem) {
		el.disabled = true;
		let customerId = builderElement.querySelector('#estimator-checkout').querySelector('#main').querySelector('#variants').querySelector('#estimator-customer-id').value;
		let cartId = builderElement.querySelector('#estimator-checkout').querySelector('#main').querySelector('#variants').querySelector('#estimator-cart-id').value;

		const url_variant = host + '/pub/v1/customers/' + customerId + '/carts/' + cartId + '/cart_items/' + cartItem;

		fetch(url_variant, {
			method: 'DELETE',
			headers: {
				'mode': 'no-cors',
				'Content-Type': 'application/json',
				'x-api-key': apiKey,
				'Accept': '*/*',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'GET, OPTIONS, DELETE',
				'Access-Control-Allow-Headers': 'X-PINGOTHER, Content-Type'
			}
		})
			.then((res) => {
				el.disabled = false;
				if (!res.ok) {
					return Promise.reject(res.json());
				}
				return Promise.resolve(res.json())
			})
			.then((data) => {
				el.parentNode.classList.remove('added');
				el.setAttribute('data-cartitem', '');


				setTotal(data.total);
			})
			.catch((err) => {
				err.then((error) => {
					showError(error.error);
				});
			});
	}

	function addToCart(el, variant, fixed, hourly, rate_flat, per_hour, overflow, zip) {
		el.disabled = true;

		let data_variant = {
			"variant_id": variant,
			"rate_fixed_id": fixed,
			"rate_flat_id": rate_flat,
			"hour_id": hourly,
			"rate_per_hour_id": per_hour,
			"rate_overflow_id": overflow,
			"zip": zip
		};
		let size;

		if (builderElement.querySelector('#estimator-checkout').querySelector('#main').querySelector('#variants').querySelector('#estimator-size-id')) {
			size = builderElement.querySelector('#estimator-checkout').querySelector('#main').querySelector('#variants').querySelector('#estimator-size-id').value
		}
		if (size) {
			data_variant["size"] = size;
		}

		let customerId = builderElement.querySelector('#estimator-checkout').querySelector('#main').querySelector('#variants').querySelector('#estimator-customer-id').value;
		let cartId = builderElement.querySelector('#estimator-checkout').querySelector('#main').querySelector('#variants').querySelector('#estimator-cart-id').value
		/*variant request*/

		const url_variant = host + '/pub/v1/customers/' + customerId + '/carts/' + cartId + '/cart_items';
		fetch(url_variant, {
			method: 'POST', // or 'PUT'
			body: JSON.stringify(data_variant), // data can be `string` or {object}!
			headers: {
				'mode': 'no-cors',
				'Content-Type': 'application/json',
				'x-api-key': apiKey,
				'Accept': '*/*',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
				'Access-Control-Allow-Headers': 'X-PINGOTHER, Content-Type'
			}
		})
			.then((res) => {
				el.disabled = false;
				if (!res.ok) {
					return Promise.reject(res.json());
				}
				return Promise.resolve(res.json())
			})
			.then((data) => {
				let existing = el.parentNode.parentNode.parentNode.parentNode.querySelectorAll('.item-list-item-cart.added');
				existing.forEach(function (el, index) {
					el.classList.remove('added');
				});

				el.parentNode.classList.add('added');

				for (let mm = 0; mm < data.cart_items.length; mm++) {
					if (data.cart_items[mm].variant_id === variant) {
						el.nextElementSibling.setAttribute('data-cartitem', data.cart_items[mm].id);
					}
				}

				setTotal(data.total);
			})
			.catch((err) => {
				err.then((error) => {
					showError(error.error);
				});
			});
	}

	function addToOptionView(el, variant, fixed, hourly, rate_flat, per_hour, overflow, zip) {
		const option = el.parentNode.getAttribute('data-option');
		const closest = getClosest(el, '.bt-et-added');
		const closestOption = closest.getAttribute('data-option');
		if (closestOption === option) {
			getClosest(el, '.bt-et-added').classList.remove('bt-et-added');
		}
		el.disabled = true;

		let data_variant = {
			"variant_id": variant,
			"rate_fixed_id": fixed,
			"rate_flat_id": rate_flat,
			"hour_id": hourly,
			"rate_per_hour_id": per_hour,
			"rate_overflow_id": overflow
		};

		if (shop_on_behalf_of > 0) {
			data_variant["shop_on_behalf_of"] = shop_on_behalf_of;
		}

		if (el.getAttribute('data-servicecharge')) {
			data_variant.answer_service_charge_id = el.getAttribute('data-servicecharge');
		}

		if (el.getAttribute('data-variantcharge')) {
			data_variant.answer_variant_charge_id = el.getAttribute('data-variantcharge');
		}

		data_variant.customer = builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-customer-id').value;
		data_variant.cart_id = builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-cart-id').value;

		if (builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-size-id')) {
			data_variant.size = builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-size-id').value
		}

		if (builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-zip-id')) {
			data_variant.zip = builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-zip-id').value
		}

		const url_variant = host + '/templator/v1/cart_action?act=add_to_option_view';
		fetch(url_variant, {
			method: 'POST', // or 'PUT'
			body: JSON.stringify(data_variant), // data can be `string` or {object}!
			headers: {
				'mode': 'no-cors',
				'Content-Type': 'application/json',
				'x-api-key': apiKey,
				'Accept': '*/*',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
				'Access-Control-Allow-Headers': 'X-PINGOTHER, Content-Type'
			}
		})
			.then((res) => {
				el.disabled = false;
				if (!res.ok) {
					return Promise.reject(res.json());
				}
				return Promise.resolve(res.text())
			})
			.then((data) => {
				el.parentNode.classList.add('bt-et-added');


				var html = data.trim(); // Never return a text node of whitespace as the result
				builderElement.querySelector('#bt-et-mini-cart').innerHTML = html;
			})
			.catch((err) => {
				err.then((error) => {
					showError(error.error);
				});
			});
	}

	function addToCartView(el, variant, fixed, hourly, rate_flat, per_hour, overflow, zip) {

		getClosest(el, '.bt-et-added').classList.remove('bt-et-added');
		el.parentNode.classList.add('bt-et-added');

		el.disabled = true;
		let data_cart_rate = el.getAttribute('data-rate');
		let data_package_variant = el.getAttribute('data-package-variant');
		let data_quantity = el.getAttribute('data-quantity');

		let data_variant = {
			"variant_id": variant,
			"package_variant_id": data_package_variant,
			"cart_rate": data_cart_rate,
			"quantity": data_quantity,
			"rate_fixed_id": fixed,
			"rate_flat_id": rate_flat,
			"hour_id": hourly,
			"rate_per_hour_id": per_hour,
			"rate_overflow_id": overflow
		};

		if (shop_on_behalf_of > 0) {
			data_variant["shop_on_behalf_of"] = shop_on_behalf_of;
		}

		if (el.getAttribute('data-servicecharge')) {
			data_variant.answer_service_charge_id = el.getAttribute('data-servicecharge');
		}

		if (el.getAttribute('data-variantcharge')) {
			data_variant.answer_variant_charge_id = el.getAttribute('data-variantcharge');
		}

		data_variant.customer = builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-customer-id').value;
		data_variant.cart_id = builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-cart-id').value;

		if (builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-size-id')) {
			data_variant.size = builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-size-id').value
		} else if (builderElement.querySelector('#builder-data-hidden-services').querySelector('input[data-tag=\"size\"]')) {
			data_variant.size = builderElement.querySelector('#builder-data-hidden-services').querySelector('input[data-tag=\"size\"]').value
		}

		if (builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-zip-id')) {
			data_variant.zip = builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-zip-id').value
		}

		const url_variant = host + '/templator/v1/cart_action?act=add_to_cart_view';
		fetch(url_variant, {
			method: 'POST', // or 'PUT'
			body: JSON.stringify(data_variant), // data can be `string` or {object}!
			headers: {
				'mode': 'no-cors',
				'Content-Type': 'application/json',
				'x-api-key': apiKey,
				'Accept': '*/*',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
				'Access-Control-Allow-Headers': 'X-PINGOTHER, Content-Type'
			}
		})
			.then((res) => {
				el.disabled = false;
				if (!res.ok) {
					return Promise.reject(res.json());
				}
				return Promise.resolve(res.text())
			})
			.then((data) => {
				el.parentNode.classList.add('bt-et-added');


				var html = data.trim(); // Never return a text node of whitespace as the result
				builderElement.querySelector('#bt-et-mini-cart').innerHTML = html;
			})
			.catch((err) => {
				err.then((error) => {
					showError(error.error);
				});
			});
	}

	function removeToCartView(el, variant) {
		el.parentNode.classList.remove('bt-et-added');
		el.disabled = true;
		let data_package_variant = el.getAttribute('data-package-variant');
		let data_variant = {
			"variant_id": variant,
			"package_variant_id": data_package_variant,
		};

		if (shop_on_behalf_of > 0) {
			data_variant["shop_on_behalf_of"] = shop_on_behalf_of;
		}

		data_variant.customer = builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-customer-id').value;
		data_variant.cart_id = builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-cart-id').value;
		const url_variant = host + '/templator/v1/cart_action?act=remove_to_cart_view';
		fetch(url_variant, {
			method: 'POST', // or 'PUT'
			body: JSON.stringify(data_variant), // data can be `string` or {object}!
			headers: {
				'mode': 'no-cors',
				'Content-Type': 'application/json',
				'x-api-key': apiKey,
				'Accept': '*/*',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
				'Access-Control-Allow-Headers': 'X-PINGOTHER, Content-Type'
			}
		})
			.then((res) => {
				el.disabled = false;
				if (!res.ok) {
					return Promise.reject(res.json());
				}
				return Promise.resolve(res.text())
			})
			.then((data) => {
				el.parentNode.classList.remove('bt-et-added');
				builderElement.querySelectorAll('.bt-et-remove-item-from-cart-back-view[data-variant="' + variant + '"]').forEach(function (node) {
					node.parentNode.classList.remove('bt-et-added')
				});

				var html = data.trim(); // Never return a text node of whitespace as the result
				builderElement.querySelector('#bt-et-mini-cart').innerHTML = html;
			})
			.catch((err) => {
				err.then((error) => {
					showError(error.error);
				});
			});
	}

	function orderCart(el) {
		if (document.getElementById('builder-acknowledgements')) {
			if (document.getElementById('builder-acknowledgements').querySelectorAll('input').length) {
				try {
					var foundUnchecked = document.getElementById('builder-acknowledgements').querySelectorAll('input').forEach(function (val) {
						if (val.type === "checkbox" && val.required && !val.checked) {
							throw "Please accept agreements";
						}
					});
				} catch (e) {
					showError(e);
					return;
				}
			}
		}

		let url_variant = host + '/templator/v1/take_action?act=checkout_order';

		let data = {}
		data.customer = builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-customer-id').value;
		data.cart = builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-cart-id').value;
		if (builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-size-id')) {
			data.size = builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-size-id').value
		}

		if (builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-zip-id')) {
			data.zip = builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-zip-id').value
		}

		if (el.getAttribute('data-paymentoption')) {
			data.payment_option = el.getAttribute('data-paymentoption');
		}

		if (builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-credit-card-id')) {
			data.credit_card_id = builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-credit-card-id').value
		}

		fetch(url_variant, {
			method: 'POST', // or 'PUT'
			body: JSON.stringify(data),
			headers: {
				'mode': 'no-cors',
				'Content-Type': 'text/html',
				'x-api-key': apiKey,
				'Accept': '*/*',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
				'Access-Control-Allow-Headers': 'X-PINGOTHER, Content-Type'
			}
		})
			.then((res) => {
				if (!res.ok) {
					return Promise.reject(res.json());
				}
				return Promise.resolve(res.text())
			})
			.then((data) => {
				if (builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-checkout-redirect-url') && builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-checkout-redirect-url').value) {
					var url = builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-checkout-redirect-url').value;

					if (url.indexOf('email=') >= 0 && builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-customer-email') && builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-customer-email').value) {
						url = url.replace('EMAIL', builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-customer-email').value)
					}
					if (url.indexOf('phone=') >= 0 && builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-customer-phone') && builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-customer-phone').value) {
						url = url.replace('PHONE', builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-customer-phone').value)
					}
					if (url.indexOf('first_name=') >= 0 && builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-customer-firstname') && builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-customer-firstname').value) {
						url = url.replace('FIRSTNAME', builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-customer-firstname').value)
					}
					if (url.indexOf('last_name=') >= 0 && builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-customer-lastname') && builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-customer-lastname').value) {
						url = url.replace('LASTNAME', builderElement.querySelector('#builder-data-hidden-main').querySelector('#builder-customer-lastname').value)
					}
					window.location.href = url;
					builderElement.innerHTML = showLoading();
					//el
				} else {
					history.pushState(
						{
							id: "order cart"
						},
						"Instant Quoting",
						'#order');
					var html = data.trim(); // Never return a text node of whitespace as the result
					builderElement.querySelector('#builder-checkout').classList.add('builder-hidden');
					builderElement.querySelector('#builder-checkout').classList.remove('builder-active');
					builderElement.querySelector('#builder-order').classList.remove('builder-hidden');
					builderElement.querySelector('#builder-order').classList.add('builder-active');

					builderElement.querySelector('#builder-order').innerHTML = html;
				}
			})
			.catch((err) => {
				err.then((error) => {
					showError(error.error);
				});
			});
	}

	function deleteCoupon(el, coupon){
		let customerId = builderElement.querySelector('#builder-data-hidden-main #builder-customer-id').value //.querySelector('#main').querySelector('#variants').querySelector('#estimator-customer-id').value;
		let cartId = builderElement.querySelector('#builder-data-hidden-main #builder-cart-id').value //.querySelector('#main').querySelector('#variants').querySelector('#estimator-cart-id').value;

		const url_variant = host + '/pub/v1/customers/' + customerId + '/carts/' + cartId + '/cart_coupons/' + coupon;
		fetch(url_variant, {
			method: 'DELETE', // or 'PUT'
			headers: {
				'mode': 'no-cors',
				'Content-Type': 'application/json',
				'x-api-key': apiKey,
				'Accept': '*/*',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
				'Access-Control-Allow-Headers': 'X-PINGOTHER, Content-Type'
			}
		})
			.then((res) => {
				el.disabled = false;
				if (!res.ok) {
					return Promise.reject(res.json());
				}
				return Promise.resolve(res.text())
			})
			.then((data) => {
				if (document.getElementById('table-wrapper')) {
					document.getElementById('table-wrapper').innerHTML = data;
				}
			}).catch((err) => {

				err.then((error) => {
					showError(error.error);
				})
			});
	}

	function addUpsale(el, upsale, variant, fixed, hourly, per_hour, overflow, zip, onCheckout) {
		el.disabled = true;

		let data_variant = { "upsale_id": upsale, "variant_id": variant, "rate_fixed_id": fixed, "hour_id": hourly, "rate_per_hour_id": per_hour, "zip": zip };
		let customerId = builderElement.querySelector('#estimator-checkout').querySelector('#main').querySelector('#variants').querySelector('#estimator-customer-id').value;
		let cartId = builderElement.querySelector('#estimator-checkout').querySelector('#main').querySelector('#variants').querySelector('#estimator-cart-id').value
		/*variant request*/

		const url_variant = host + '/pub/v1/customers/' + customerId + '/carts/' + cartId + '/cart_items';
		fetch(url_variant, {
			method: 'POST', // or 'PUT'
			body: JSON.stringify(data_variant), // data can be `string` or {object}!
			headers: {
				'mode': 'no-cors',
				'Content-Type': 'application/json',
				'x-api-key': apiKey,
				'Accept': '*/*',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
				'Access-Control-Allow-Headers': 'X-PINGOTHER, Content-Type'
			}
		})
			.then((res) => {
				el.disabled = false;
				if (!res.ok) {
					return Promise.reject(res.json());
				}
				return Promise.resolve(res.json())
			})
			.then((data) => {
				el.parentNode.classList.add('added');

				for (let mm = 0; mm < data.cart_items.length; mm++) {
					if (data.cart_items[mm].variant_id === variant) {
						el.nextElementSibling.setAttribute('data-cartitem', data.cart_items[mm].id);
					}
				}

				setTotal(data.total);

				if (onCheckout) {
					const url_variant = host + '/pub/v1/customers/' + customerId + '/carts/' + cartId + '/quick_cart';
					fetch(url_variant, {
						method: 'GET', // or 'PUT'
						headers: {
							'mode': 'no-cors',
							'Content-Type': 'application/json',
							'x-api-key': apiKey,
							'Accept': '*/*',
							'Access-Control-Allow-Origin': '*',
							'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
							'Access-Control-Allow-Headers': 'X-PINGOTHER, Content-Type'
						}
					})
						.then((res) => {
							el.disabled = false;
							if (!res.ok) {
								return Promise.reject(res.json());
							}
							return Promise.resolve(res.text())
						})
						.then((data) => {
							if (document.getElementById('table-wrapper')) {
								document.getElementById('table-wrapper').innerHTML = data;
							}
						})
						.catch((err) => {
							err.then((error) => {
								showError(error.error);
							});
						});
				}
			})
			.catch((err) => {
				err.then((error) => {
					showError(error.error);
				});
			});
	}

	function setTotal(total) {
		document.querySelectorAll('.update-estimator-cart-price').forEach(function (el, idx) {
			el.innerHTML = total;
		});
	}

	function showSuccess(successText) {
		var template = document.createElement('template');
		var html = '<div class="estimator-global-modal estimator-global-modal-show"><div class="overlay"></div><div class="estimator-global-modal_contents modal-transition"><div class="estimator-global-modal-header"><span class="mobile-close"> X </span><h3> <span> Success!</span></h3></div><div class="estimator-global-modal-body"><div class="content-left">' + successText + '</div></div><div class="estimator-global-modal-footer"><button type="button" onclick="estimatorOnClose()" class="estimator-btn estimator-btn-outline-primary">CLOSE</button></div></div></div>'; // Never return a text node of whitespace as the result
		template.innerHTML = html;

		document.body.appendChild(template.content);
	}

	function showError(errorText) {
		var template = document.createElement('template');
		var html = '<div class="estimator-global-modal estimator-global-modal-show"><div class="overlay"></div><div class="estimator-global-modal_contents modal-transition"><div class="estimator-global-modal-header"><span class="mobile-close"> X </span><h3> <span> Whoops!</span> <b>It looks like there is an error.</b></h3></div><div class="estimator-global-modal-body"><div class="content-left">' + errorText + '</div></div><div class="estimator-global-modal-footer"><button type="button" onclick="estimatorOnClose()" class="estimator-btn estimator-btn-outline-primary">CLOSE</button></div></div></div>'; // Never return a text node of whitespace as the result
		template.innerHTML = html;

		document.body.appendChild(template.content);
	}

	// ADDED
	function showMapInstructions() {
		var template = document.createElement('template');
		var customerContent = '';

		if (document.getElementById('tracer-inner-html') && document.getElementById('tracer-inner-html').value) {
			customerContent = document.getElementById('tracer-inner-html').value;
		} else {
			customerContent += '<p>Use the paint tool to fill in the lawn</p><div style="display: flex;"><img src="https://api.simpleestimatesystems.com/tracer-no.gif" style="max-height: 200px;"/><div style="width: 10px; height: 10px;"></div><img src="https://api.simpleestimatesystems.com/tracer.gif" style="max-height: 200px;"/></div>';
		}
		var html = '<div class="estimator-global-modal estimator-global-modal-show" style="height: 90vh;"><div class="overlay"></div><div class="estimator-global-modal_contents modal-transition"><div class="estimator-global-modal-header"><span><h3>Instructions</h3></span><div  style="display: flex; align-items: center; justify-content: center; flex-direction: column;"><button type="button" onclick="estimatorOnClose()" class="estimator-btn estimator-btn-outline-primary">CLOSE</button></div></div><div class="estimator-global-modal-body"><div style="height: 100%; display: flex; align-items: center; justify-content: center; flex-direction: column;">' + customerContent + '</div></div><div class="estimator-global-modal-footer"></div></div></div>'; // Never return a text node of whitespace as the result
		template.innerHTML = html;

		document.body.appendChild(template.content);
	}

	// ADDED
	function refShowTerms(errorText) {
		var template = document.createElement('template');
		var html = '<div class="estimator-global-modal estimator-global-modal-show"><div class="overlay"></div><div class="estimator-global-modal_contents modal-transition"><div class="estimator-global-modal-header"><span class="mobile-close"> X </span><h3> <span> Information</span></h3></div><div class="estimator-global-modal-body"><div class="content-left">' + errorText + '</div></div><div class="estimator-global-modal-footer"><button type="button" onclick="estimatorOnClose()" class="estimator-btn estimator-btn-outline-primary">CLOSE</div></div></div>'; // Never return a text node of whitespace as the result
		template.innerHTML = html;

		document.body.appendChild(template.content);
	}

	document.addEventListener('click', (event) => {
		// If the clicked element doesn't have the right selector, bail
		if (event.target.matches('.ack-read-more')) {
			event.preventDefault();
			refShowTerms(getClosest(event.target, '.ack-read-more-text').innerHTML);
		}

		if (event.target.matches('.service-read-more')) {
			event.preventDefault();
			refShowTerms(getClosest(event.target, '.service-more-info').innerHTML);
		}
	});
}
